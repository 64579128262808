import React from 'react';
import BasicModal from '../../Common/BasicModel';
import { getTimezoneAbbreviation } from '../../../helpers/getTimezoneAbbreviation';
import DataTable from 'react-data-table-component';

const SearchModal = ({
  isOpen,
  onClose,
  setSearchResults,
  searchResults,
  onFinish,
}) => {
  if (!isOpen) return null;

  const handleChangeSearch = (e) => {
    const { name, value } = e.target;
    console.log('Search', name, value);
    setSearchResults([]);
    fetch('/call-options-search', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        [name]: value,
        queryType: 'contactName',
        CallerTimeZone: getTimezoneAbbreviation(),
      }),
    }).then((r) => {
      if (r.status === 401) {
        window.location.href = '/login';
      }
      r.json().then((data) => setSearchResults(data.data));
    });
  };

  const tableColumns = [
    { name: 'LeadId', selector: (row) => row.LeadId, width: 'fit-content' },
    {
      name: 'Contact Name',
      selector: (row) => row.ContactName,
      width: 'match-parent',
    },
    {
      name: 'Company Name',
      selector: (row) => row.CompanyName,
      width: 'match-parent',
    },
  ];

  const handleRowClicked = (row) => {
    document.cookie = `ID=${row.ID}`;
    onClose();
    onFinish();
  };

  return (
    <BasicModal open={isOpen} handleClose={onClose}>
      <div
        id='search-modal'
        className='hs-overlay pointer-events-none fixed start-0 top-8 z-[60]  h-full w-full overflow-y-auto overflow-x-hidden'
      >
        <div className='m-3 mt-0  transition-all ease-out hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 sm:mx-auto sm:w-full sm:max-w-xl'>
          <div className='pointer-events-auto flex flex-col rounded-xl border bg-white shadow-sm'>
            <div className='px-2 pt-1 text-right'>
              <button
                onClick={onClose}
                type='button'
                className='inline-flex h-7 w-7 items-center justify-center rounded-full border border-transparent text-sm font-semibold text-gray-800 transition hover:bg-gray-100 disabled:pointer-events-none disabled:opacity-50 dark:text-white dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600'
                data-hs-overlay='#search-modal'
              >
                <span className='sr-only'>Close</span>
                <svg
                  className='h-4 w-4 flex-shrink-0'
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                >
                  <path d='M18 6 6 18' />
                  <path d='m6 6 12 12' />
                </svg>
              </button>
            </div>
            <div className='overflow-y-auto p-4'>
              <input
                type='text'
                id='input-label'
                name={'search'}
                className='block w-full rounded-lg border-2 border-secondary/20 px-4 py-3 text-lg font-medium transition focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500/20'
                placeholder={'Search by Contact Name'}
                autoFocus={true}
                onChange={handleChangeSearch}
              />

              <div className='mt-6'>
                {searchResults && searchResults.length ? (
                  <DataTable
                    columns={tableColumns}
                    data={searchResults}
                    highlightOnHover
                    responsive
                    onRowClicked={handleRowClicked}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </BasicModal>
  );
};

export default SearchModal;
