export default function FileIcon(props) {
  return (
    <svg
      viewBox='0 0 42 42'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={props.className}
    >
      <circle
        cx='21'
        cy='21'
        r='20'
        fill='#FFD831'
        stroke='#EBA352'
        stroke-width='2'
      />
      <path
        d='M31.7075 15.2925L24.7075 8.2925C24.6146 8.19967 24.5042 8.12605 24.3829 8.07586C24.2615 8.02568 24.1314 7.9999 24 8H12C11.4696 8 10.9609 8.21071 10.5858 8.58579C10.2107 8.96086 10 9.46957 10 10V32C10 32.5304 10.2107 33.0391 10.5858 33.4142C10.9609 33.7893 11.4696 34 12 34H30C30.5304 34 31.0391 33.7893 31.4142 33.4142C31.7893 33.0391 32 32.5304 32 32V16C32.0001 15.8686 31.9743 15.7385 31.9241 15.6172C31.8739 15.4958 31.8003 15.3854 31.7075 15.2925ZM25 11.4138L28.5863 15H25V11.4138ZM30 32H12V10H23V16C23 16.2652 23.1054 16.5196 23.2929 16.7071C23.4804 16.8946 23.7348 17 24 17H30V32ZM26 22C26 22.2652 25.8946 22.5196 25.7071 22.7071C25.5196 22.8946 25.2652 23 25 23H17C16.7348 23 16.4804 22.8946 16.2929 22.7071C16.1054 22.5196 16 22.2652 16 22C16 21.7348 16.1054 21.4804 16.2929 21.2929C16.4804 21.1054 16.7348 21 17 21H25C25.2652 21 25.5196 21.1054 25.7071 21.2929C25.8946 21.4804 26 21.7348 26 22ZM26 26C26 26.2652 25.8946 26.5196 25.7071 26.7071C25.5196 26.8946 25.2652 27 25 27H17C16.7348 27 16.4804 26.8946 16.2929 26.7071C16.1054 26.5196 16 26.2652 16 26C16 25.7348 16.1054 25.4804 16.2929 25.2929C16.4804 25.1054 16.7348 25 17 25H25C25.2652 25 25.5196 25.1054 25.7071 25.2929C25.8946 25.4804 26 25.7348 26 26Z'
        fill='#EBA352'
      />
    </svg>
  );
}
