import React, { useEffect, useState, useRef } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import moment from 'moment';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { Device } from '@twilio/voice-sdk';
import { Player } from '@lottiefiles/react-lottie-player';
import { CSVLink } from 'react-csv';
import { io } from 'socket.io-client';

import { updateHelperSlice } from '../../store/slices/helperSlice';
import ManagerDashboard from '../../pages/CallerDashboard';
import ScriptTab from '../CallProcessing/Process/ScriptTab';
import BasicModal from '../Common/BasicModel';
import WhatHappened from '../CallProcessing/Process/WhatHappened';
import Schedule1 from '../CallProcessing/Process/Schedule1';
import Schedule2 from '../CallProcessing/Process/Schedule2';
import Schedule3 from '../CallProcessing/Process/Schedule3';
import Schedule4 from '../CallProcessing/Process/Schedule4';
import Email1 from '../CallProcessing/Process/Email1';
import Email2 from '../CallProcessing/Process/Email2';
import Email3 from '../CallProcessing/Process/Email3';
import Referral from '../CallProcessing/Process/Referral';
import Referral2 from '../CallProcessing/Process/Referral2';
import Referral3 from '../CallProcessing/Process/Referral3';
import Referral4 from '../CallProcessing/Process/Referral4';
import { Referral5 } from '../CallProcessing/Process/Referral5';
import Referral6 from '../CallProcessing/Process/Referral6';
import Hungup from '../CallProcessing/Process/Hungup';
import NotInterested from '../CallProcessing/Process/NotInterested';
import Discard from '../CallProcessing/Process/Discard';
import CommonDiscard from '../CallProcessing/Process/CommonDiscard';
import Appointment from '../CallProcessing/Process/Appointment';
import PointerStats from './PointerStats';
import Practice from './Practice';
import Playback from './Playback';
import CallSearchBox from './CallSearchBox';
import PlayIcon from '../../assets/svg/PlayIcon';
import FileIcon from '../../assets/svg/FileIcon';
import ClockIcon from '../../assets/svg/ClockIcon';
import BulbIcon from '../../assets/svg/BulbIcon';
import CallIcon from '../../assets/svg/CallIcon';
import EditContactModal from './Modals/EditContactModal';
import SearchModal from './Modals/SearchModal';
import CallNotesModal from './Modals/CallNotesModal';
import DiscardModal from './Modals/DiscardModal';
import { getTimezoneAbbreviation } from '../../helpers/getTimezoneAbbreviation';
import { formatPhoneNumber } from '../../helpers/formatPhoneNumber';

const socket = io('https://devback.accelerateiq.ai');
let trialModalOpen = false;
let noLeadModalPending = false;

const entryDetails = [
  { mode: 'Pitch', icon: <FileIcon />, label: 'Pitch' },
  { mode: 'Pointers', icon: <BulbIcon />, label: 'Pointers' },
  { mode: 'Practice', icon: <ClockIcon />, label: 'Practice' },
  { mode: 'Playback', icon: <PlayIcon />, label: 'Playback' },
];

const ConversionLab = () => {
  const dispatch = useDispatch();
  const [twilioDevice, setTwilioDevice] = useState(null);
  const [pageText, setPageText] = useState({});
  const [happenedIndex, setHappenedIndex] = useState(1);
  const [index, setIndex] = useState(0);
  const [pickerInfo, setPickerInfo] = useState();
  const [timeInfo, setTimeInfo] = useState();
  const [clicked, setClicked] = useState(false);
  const [loader, setLoader] = useState(false);
  const [openEditContactModal, setOpenEditContactModal] = useState(false);
  const [isOpenSearchModal, setIsOpenSearchModal] = useState(false);
  const [openCallNotesModal, setOpenCallNotesModal] = useState(false);
  const [openDeclineModal, setOpenDeclineModal] = useState(false);
  const [openOutOfLeadModal, setOpenOutOfLeadModal] = useState(false);
  const [openScriptEditModal, setOpenScriptEditModal] = useState(false);
  const [openOptimizeScriptModal, setOpenOptimizeScriptModal] = useState(false);
  const [finishClicked, setFinishClicked] = useState(false);
  const [selected, setSelected] = useState('contactName');
  const [searchResults, setSearchResults] = useState([]);
  const [formObjContact, setFormObjContact] = useState({
    leadid: '',
    company: '',
    contact: '',
    title: '',
    industry: '',
    time: '',
    address: '',
    city: '',
    state: '',
    contactId: '',
    website: '',
    linkedin: '',
    email: '',
    zip: '',
    apptDate: '',
    apptTime: '',
    apptType: '',
    bestPhone: '',
    userId: '',
  });
  const [websiteUrl, setWebSiteUrl] = useState('');
  const [tabIndex, setTabIndex] = useState(0);
  const [topBar, setTopBar] = useState({});
  const [chatgptResponse, setChatgptResponse] = useState('');
  const [scriptText, setScriptText] = useState('');
  const [objSelected, setObjSelected] = useState(false);
  const [formObjNotes, setFormObjNotes] = useState({
    callNotes: '',
  });
  const [appointment, setAppointment] = useState('Discovery Call');
  const [isPlayer, setIsPlayer] = useState('hidden');
  const [emailType, setEmailType] = useState('');
  const [hangupClicked, setHangupClicked] = useState(false);
  const [formObj, setFormObj] = useState({
    name: '',
    title: '',
    phone: '',
    time: 'EST',
    email: '',
  });
  const [entryMode, setEntryMode] = useState('');
  const [seeTipsClicked, setSeeTipsClicked] = useState(false);
  const [dialOutcomeDetail, setDialOutcomeDetail] = useState('');
  const [practiceCallDialed, setPracticeCallDialed] = useState(false);
  const [mainObjectionList, setMainObjectionList] = useState([]);
  const [subObjectionsList, setSubObjectionsList] = useState([]);
  const [curObjection, setCurObjection] = useState({
    MainObjectionResponse: '',
    MainObjection: '',
  });
  const [curSubObjection, setCurSubObjection] = useState({
    SubObjectionResponse: '',
    SubObjection: '',
  });
  const [subObjSelected, setSubObjSelected] = useState(false);
  const [isScriptBubbleUp, setIsScriptBubbleUp] = useState(false);
  const [isBubbleUp, setIsBubbleUp] = useState(false);
  const [isSubBubbleUp, setIsSubBubbleUp] = useState(false);
  const [isGPTForObj, setIsGPTForObj] = useState(false);
  const [gptObjectionResponse, setGptObjectionResponse] = useState('');
  const [formCallNotes, setFormCallNotes] = useState('');
  const [contactExpanded, setContactExpanded] = useState(false);
  const [triggerCallOptions, setTriggerCallOptions] = useState(0);
  const [triggerCallerScipts, setTriggerCallerScipts] = useState(0);
  const [triggerRecList, setTriggerRecList] = useState(0);
  const [currentScriptName, setCurrentScriptName] = useState('');
  const [scriptEdit, setScriptEdit] = useState({
    ScriptName: '',
    ScriptDescription: '',
    DefaultScript: false,
  });
  const [scriptSelected, setScriptSelected] = useState(false);
  const [callerScripts, setCallerScripts] = useState([]);
  const [showTenthDialModal, setShowTenthDialModal] = useState(false);
  const [show20thDialModal, setShow20thDialModal] = useState(false);
  const [leadLoaded, setLeadLoaded] = useState(false);
  const [isDialDisabled, setIsDialDisabled] = useState(false);
  const [callSID, setCallSID] = useState('');

  const handleOpenTenthModal = () => {
    trialModalOpen = true;
    setShowTenthDialModal(true);
  };
  const handleCloseTenthModal = () => {
    setShowTenthDialModal(false);
    if (noLeadModalPending) {
      handleOpenOutOfLeadModal();
      noLeadModalPending = false;
      trialModalOpen = false;
    }
  };

  const handleOpen20thModal = () => {
    trialModalOpen = true;
    setShow20thDialModal(true);
  };
  const handleClose20thModal = () => {
    setShow20thDialModal(false);
    if (noLeadModalPending) {
      handleOpenOutOfLeadModal();
      noLeadModalPending = false;
      trialModalOpen = false;
    }
  };

  const handleOpenOutOfLeadModal = () => setOpenOutOfLeadModal(true);
  const handleCloseOutOfLeadModal = () => setOpenOutOfLeadModal(false);

  const handleOpenOptimizeScriptModal = () => setOpenOptimizeScriptModal(true);
  const handleCloseOptimizeScriptModal = () =>
    setOpenOptimizeScriptModal(false);

  const handleOpenScriptEditModal = () => setOpenScriptEditModal(true);
  const handleCloseScriptEditModal = () => setOpenScriptEditModal(false);

  const getUserTimeZone = () => {
    const userTimeZone = moment.tz.guess(); // Get user's time zone
    const userTimeZoneAbbreviation = moment().tz(userTimeZone).format('z');
    return userTimeZoneAbbreviation;
  };

  const csvData = [
    [
      'LeadID',
      'Company Name',
      'Contact Name',
      'Title',
      'Industry',
      'Contact Address',
      'City',
      'State',
      'Zip Code',
      'Time Zone',
      'Email',
      'Linkedin',
      'Website',
      'Appt Date',
      'Appt Time',
      'Appt Type',
      'Best Phone',
    ],
    [
      formObjContact.leadid,
      formObjContact.company,
      formObjContact.contact,
      formObjContact.title,
      formObjContact.industry,
      formObjContact.address,
      formObjContact.city,
      formObjContact.state,
      formObjContact.zip,
      formObjContact.time,
      formObjContact.email,
      formObjContact.linkedin,
      formObjContact.website,
      moment(pickerInfo).format('MM/DD/YYYY'),
      `${timeInfo} ${getUserTimeZone()}`,
      appointment,
      formObjContact.bestPhone,
    ],
  ];

  const handleGetGPTResponse = (draftResponse, objection) => {
    setGptObjectionResponse('');
    var promptForObjection;
    if (!objection) {
      promptForObjection = `I'm a cold caller. Can you provide a more polished and friendly version of the text? Here is the draft: ${draftResponse}
        Please return answers with no extra words like 'Absolutely! Here is a more polished and friendly version...' or ' Here is a refined version ...' or 'Certainly! Here's a revised version...' or 'Certainly! Here you go...'.`;
    } else if (objection === 'Call me back') {
      promptForObjection = `What is a good rebuttal when someone says "call me back" on a cold call where I am trying to schedule a demo of a software solution? Response should be 50 words or more and should be focused on asking the best time to call back and  should only include the rebuttal, no extra enclosing words around content, no quotation mark, no extra words like Certainly, Absolutely, I understand etc.`;
    } else if (objection === 'How did you get my number?') {
      promptForObjection = `What is a good rebuttal when someone says "How did you get my number?" on a cold call where I am trying to schedule a demo of a software solution? Response should only include the rebuttal and it should be 50 words or more and should include 2 optional reasons for why they are not interested and should only include the rebuttal, no extra enclosing words around content, no quotation mark, no extra words like Certainly, Absolutely, I understand, A good rebuttal to, etc.`;
    } else if (objection === "I can't talk right now, I'm busy") {
      promptForObjection = `What is a good rebuttal when someone says "I' can't talk now, I'm busy" on a cold call where I am trying to schedule a demo of a software solution? Response should only include the rebuttal and it should be 50 words or more and should include 2 optional reasons for why they are not interested and should only include the rebuttal, no extra enclosing words around content, no quotation mark, no extra words like Certainly, Absolutely, I understand, A good rebuttal to, etc.`;
    } else if (objection === "I'm not interested") {
      setGptObjectionResponse(draftResponse);
      return;
    } else if (objection === "I'm not the right person") {
      promptForObjection = `What is a good rebuttal when someone says "I'm not the right person" on a cold call where I am trying to schedule a demo of a software solution? Response should only include the rebuttal and it should be 50 words and should only include the rebuttal, no extra enclosing words around content, no quotation mark, no extra words like Certainly, Absolutely, I understand, A good rebuttal to, etc.`;
    } else if (objection === "I'm walking into a meeting") {
      promptForObjection = `What is a good rebuttal when someone says "I'm walking into a meeting" on a cold call where I am trying to schedule a demo of a software solution? Response should only include the rebuttal and it should be 50 words or more and should be focused on asking the best time to call back and should only include the rebuttal, no extra enclosing words around content, no quotation mark, no extra words like Certainly, Absolutely, I understand, A good rebuttal to, etc.`;
    } else if (objection === 'Is this a cold call?') {
      promptForObjection = `What is a good rebuttal when someone says "Is this a cold call" on a cold call where I am trying to schedule a demo of a software solution? Response should only include the rebuttal and it should be 50 words or more and should only include the rebuttal, no extra enclosing words around content, no quotation mark, no extra words like Certainly, Absolutely, I understand, A good rebuttal to, etc.`;
    } else if (objection === 'Send me an email') {
      promptForObjection = `What is a good rebuttal when someone says "send me an email" on a cold call where I am trying to schedule a demo of a software solution? Response should only include the rebuttal and it should be 50 words or more and should only include the rebuttal, no extra enclosing words around content, no quotation mark, no extra words like Certainly, Absolutely, I understand, A good rebuttal to, etc.`;
    } else if (objection === 'What are you selling?') {
      promptForObjection = `What is a good rebuttal when someone says "what are you selling?" on a cold call where I am trying to schedule a demo of a software solution? Response should only include the rebuttal and it should be 50 words or more and should only include the rebuttal, no extra enclosing words around content, no quotation mark, no extra words like Certainly, Absolutely, I understand, A good rebuttal to, etc.`;
    } else if (objection === "What's this call about?") {
      promptForObjection = `What is a good rebuttal when someone says "what is this call about?" on a cold call where I am trying to schedule a demo of a software solution? Response should only include the rebuttal and it should be 50 words or more and should only include the rebuttal, no extra enclosing words around content, no quotation mark, no extra words like Certainly, Absolutely, I understand, A good rebuttal to, etc.`;
    }

    fetch('/api/v1/chatgpt', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        message: promptForObjection,
      }),
    }).then((response) => {
      if (response.status === 200) {
        response.json().then((data) => {
          setGptObjectionResponse(data.answer);
        });
      } else if (response.status === 500) {
        // Perform Ajax call again when 500 error
        fetch('/api/v1/chatgpt', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            message: promptForObjection,
          }),
        }).then((resp) => {
          if (resp.status === 200) {
            resp.json().then((respData) => {
              setGptObjectionResponse(respData.answer);
            });
          }
        });
      }
    });
  };

  const handleObjectionSelect = (objectionSeleted) => {
    console.log(objectionSeleted);
    setCurObjection(objectionSeleted);
    setObjSelected(true); //setSubObjectionsList
    setSubObjectionsList([]);
    fetch('/api/v1/pitch/sub-objection', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        objection: objectionSeleted.MainObjection.split("'").join("''"),
      }),
    }).then((r) => {
      if (r.ok) {
        r.json().then((data) => {
          console.log('sub-objection', data); // MainObjection, MainObjectionResponse
          setSubObjectionsList(data.subobjections);
        });
      } else {
        setSubObjectionsList([]);
        console.log('Sub-Objections fetch failed!');
      }
    });
  };

  const handleSubObjectionSelect = (item) => {
    setCurSubObjection(item);
    setSubObjSelected(true);
  };

  const handleFinish = () => {
    console.log('Finish');
    fetch('/find-lead', {
      method: 'GET',
    }).then((res) => {
      if (res.status === 200) {
        console.log('Success');
        setFinishClicked(false);
        setHappenedIndex(1); // set default happened index
        setIndex(0);
        setTriggerCallOptions(triggerCallOptions + 1);
      } else if (res.status === 401) {
        window.location.href = '/login';
      } else {
        console.log('GET /find-lead Internal server error');
      }
    });
  };

  // const updateDialOutcomes = (happenedIndex, dialOutcome='') => {
  //     let choice = '';
  //     let detail = '';
  //     let scheduleType = 0;
  //     let AppointmentDateTime = ''
  //     let AppointmentType = ''
  //     if (happenedIndex === 0) {
  //         choice = 'FirstAppt';
  //         detail = 'N/A';
  //         scheduleType = 2;
  //         AppointmentDateTime = moment(pickerInfo).format('YYYY-MM-DD') + ' ' + timeInfo;
  //         AppointmentType = appointment;
  //     } else if (happenedIndex === 1) {
  //         choice = 'Callback';
  //         detail = 'N/A';
  //         scheduleType = 1;
  //         AppointmentDateTime = moment(pickerInfo).format('YYYY-MM-DD') + ' ' + timeInfo;
  //     } else if (happenedIndex === 2) {
  //         choice = 'EmailSent';
  //         detail = 'N/A'
  //     } else if (happenedIndex === 3) {
  //         choice = 'NewContact';
  //         detail = 'N/A'
  //
  //     } else if (happenedIndex === 4) {
  //         choice = 'HangUp';
  //         detail = 'N/A'
  //
  //     } else if (happenedIndex === 5) {
  //         choice = 'NotInterested';
  //         detail = 'N/A'
  //     } else if (happenedIndex === 6) {
  //         choice = 'NoAns';
  //         detail = 'N/A'
  //     } else if (happenedIndex === 7) {
  //         choice = 'Discarded';
  //         detail = dialOutcome
  //     } else if (happenedIndex === 8) {
  //         choice = 'DoNotCall';
  //         detail = dialOutcomeDetail;
  //     }
  //     console.log(happenedIndex, choice, detail)
  //
  //     fetch('/dialoutcomes', {
  //         method: 'POST',
  //         headers: {
  //             'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify({
  //             dialOutcome: choice,
  //             detail: detail,
  //             scheduleType: scheduleType,
  //             AppointmentDate: AppointmentDateTime,
  //             AppointmentType: AppointmentType
  //         })
  //     }).then(response => {
  //         if (response.status === 200) {
  //             response.json().then(data => {
  //                 console.log(data)
  //             })
  //         }
  //     })
  // };

  const updateDialOutcomes = (happenedIndex, dialOutcome = '') => {
    let choice = '';
    let detail = '';
    let scheduleType = 0;
    let AppointmentDateTime = '';
    let AppointmentType = '';
    let CallerTimeZone = getTimezoneAbbreviation();
    if (happenedIndex === 0) {
      choice = 'FirstAppt';
      detail = 'N/A';
      scheduleType = 2;
      AppointmentDateTime =
        moment(pickerInfo).format('YYYY-MM-DD') + ' ' + timeInfo;
      AppointmentType = appointment;

      // AppointmentDateTime = '4/15/2024 10:30:00';
    } else if (happenedIndex === 1) {
      choice = 'Callback';
      detail = 'N/A';
      scheduleType = 1;
      AppointmentDateTime =
        moment(pickerInfo).format('YYYY-MM-DD') + ' ' + timeInfo;

      // AppointmentDateTime = '3/15/2024 09:30:00';
    } else if (happenedIndex === 2) {
      choice = 'EmailSent';
      detail = 'N/A';
    } else if (happenedIndex === 3) {
      choice = 'NewContact';
      detail = 'N/A';
    } else if (happenedIndex === 4) {
      choice = 'HangUp';
      detail = 'N/A';
    } else if (happenedIndex === 5) {
      choice = 'NotInterested';
      detail = 'N/A';
    } else if (happenedIndex === 6) {
      choice = 'NoAns';
      detail = 'N/A';
    } else if (happenedIndex === 7) {
      choice = 'Discarded';
      detail = dialOutcome;
    } else if (happenedIndex === 8) {
      choice = 'DoNotCall';
      detail = dialOutcome;
    }
    console.log(happenedIndex, choice, detail);

    fetch('/dialoutcomes', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        dialOutcome: choice,
        detail: detail,
        scheduleType: scheduleType,
        AppointmentDate: AppointmentDateTime,
        AppointmentType: AppointmentType,
        CallerTimeZone: CallerTimeZone,
        callSid: callSID,
      }),
    }).then((response) => {
      if (response.status === 200) {
        let totalDials = localStorage.getItem('totalDials');
        localStorage.setItem('totalDials', parseInt(totalDials) + 1);
        if (parseInt(totalDials) === 19) {
          handleOpen20thModal();
        } else if (parseInt(totalDials) === 9) {
          handleOpenTenthModal();
        }
        response.json().then((data) => {
          Cookies.remove('ID', { path: '' });
          console.log(data);
          setTimeout(() => {
            setTriggerRecList(triggerRecList + 1);
          }, 3000);
        });
        // Fetch call metrics
        const today = moment();
        const thisMonthStart = today
          .clone()
          .startOf('month')
          .format('YYYY-MM-DD');
        const thisMonthEnd = today.clone().endOf('month').format('YYYY-MM-DD');
        fetch('/cdashboard', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            startDate: thisMonthStart,
            endDate: thisMonthEnd,
            reportType: 'All',
          }),
        }).then((response) => {
          if (response.status === 200) {
            response.json().then((data) => {
              console.log('Call metrics', data);
              const conPerc =
                data?.dashboardData?.query2?.[0]?.ConnectionPerc ?? 0;
              setCallerTotalConnectionRate((conPerc * 100).toFixed(0));
            });
          }
        });
      } else if (response.status === 401) {
        window.location.href = '/login';
      } else {
        console.log('GET /dialoutcomes Internal server error');
      }
    });
  };

  const handleOpenEditContactModal = () => setOpenEditContactModal(true);
  const handleCloseEditContactModal = () => setOpenEditContactModal(false);

  const handleOpenSearchModal = () => setIsOpenSearchModal(true);
  const handleCloseSearchModal = () => setIsOpenSearchModal(false);

  const handleOpenCallNotesModal = () => setOpenCallNotesModal(true);
  const handleCloseCallNotesModal = () => setOpenCallNotesModal(false);

  // Function to toggle the isPlayer state
  const toggleIsPlayer = (type) => {
    if (type === 'hidden') {
      setIsPlayer('hidden');
      console.log('hidden');
    } else {
      setIsPlayer('');
      console.log('visible');
    }
    console.log(isPlayer);
  };

  const fetchToken = async () => {
    try {
      const response = await fetch('/token', {
        method: 'GET',
      });
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  // Twillio Device connection
  useEffect(() => {
    (async () => {
      const tokenInfo = await fetchToken();
      if (tokenInfo) {
        const device = new Device(tokenInfo.token, {
          codecPreferences: ['opus', 'pcmu'],
          logLevel: 1,
          tokenRefreshMs: 30000,
        });
        device.register().then(() => {
          console.log('Device registered');
        });
        device.addListener('connect', () => {
          console.log('Connect event listener added...');
        });
        device.on('registered', () => {
          console.log('Agent registered');
          // Handle successful registration
        });
        device.on('connect', (connection) => {
          console.log('Call connected');
          // Handle successful connection
        });
        device.on('tokenWillExpire', async () => {
          const tokenInfo = await fetchToken();
          console.log('will expired after 5mins.');
          window.location.reload();
        });
        device.on('error', (error) => {
          console.log('Error: ' + error.message);
        });
        setTwilioDevice(device);
      } else {
        window.location.reload();
      }
    })();
  }, []);

  useEffect(() => {
    socket.on('message', (msg) => {
      console.log('Message from server', msg);
      setTriggerRecList(triggerRecList + 1);
    });

    return () => {
      socket.off('message');
    };
  }, []);

  const handleDialClick = () => {
    console.log('clicked', clicked);
    if (!isDialDisabled) {
      setIsDialDisabled(true);
      setLoader(true);
      dispatch(updateHelperSlice({ name: 'showWave', value: true }));
      setTimeout(() => {
        if (clicked) {
          twilioDevice.disconnectAll();
          setLoader(false);
          setClicked(false);
          setHangupClicked(true);
          setIndex(1);
          dispatch(updateHelperSlice({ name: 'showWave', value: false }));
        } else {
          setClicked(true);
          initiateCall();
          setLoader(false);
          dispatch(updateHelperSlice({ name: 'showWave', value: false }));
        }
        setIsDialDisabled(false);
      }, 1000);
    }
  };

  const handleSubmitScriptEdit = (e) => {
    e.preventDefault();
    handleCloseScriptEditModal();
    // Get caller Scripts
    if (scriptEdit.ScriptName === '' || scriptEdit.ScriptDescription === '') {
      toast.info('Please provide Script Name and Description', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
      return;
    }
    const scriptDescriptionUpdate = scriptEdit.ScriptDescription.replaceAll(
      "'",
      "''"
    );
    fetch('/api/script/update-script', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ScriptName: scriptEdit.ScriptName,
        ScriptDescription: scriptDescriptionUpdate,
        CallerScriptID: scriptEdit.CallerScriptID,
      }),
    }).then((response) => {
      if (response.status === 200) {
        if (scriptSelected) {
          setTimeout(() => handleOpenOptimizeScriptModal(), 2800);
          setScriptSelected(false);
          setTimeout(() => setScriptSelected(true), 10);
        }
        console.log('Script update success');
        setTimeout(() => setTriggerCallerScipts(triggerCallerScipts + 1), 500);
      }
    });
  };

  const handleSubmitScriptDelete = (e) => {
    e.preventDefault();
    handleCloseScriptEditModal();
    if (!scriptEdit.CallerScriptID) {
      toast.info('Cannot delete script without ID', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
      return;
    }
    fetch('/api/script/delete-script', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(scriptEdit),
    }).then((response) => {
      if (response.status === 200) {
        console.log('Script delete success');
        setScriptSelected(false);
        setTriggerCallerScipts(triggerCallerScipts + 1);
      }
    });
  };

  //     const handleRowClicked = (row) => {
  //         console.log(row)
  //
  //         // document.cookie = `LeadId=${row.LeadId}`
  //         // document.cookie = `ID=${row.ID}`
  // // .        window.location.reload()
  //     }

  const initiateCall = () => {
    // read cookie using js
    const CallerPhone = Cookies.get('CallerPhone');
    const params = {
      // To:dialNumber
      // From: '+1' + formObjContact.bestPhone,
      To: '+1' + formObjContact.bestPhone,
      From: CallerPhone,
      CallerPhone: CallerPhone,
      LeadId: formObjContact.leadid,
      CallerId: formObjContact.userId,
    };
    twilioDevice
      .connect({
        params,
        rtcConstraints: {
          audio: true,
        },
      })
      .then((connection) => {
        connection.addListener('disconnect', (conn) => {
          console.log('Call disconnected', conn.parameters.CallSid);
          setCallSID(conn.parameters.CallSid);
          setHangupClicked(true);
          setIndex(1);
        });
      });
  };

  const initiatePracticeCall = (phone) => {
    // read cookie using js
    const CallerPhone = Cookies.get('CallerPhone');
    const params = {
      To: phone,
      From: CallerPhone,
      CallerPhone: CallerPhone,
    };
    twilioDevice.connect({
      params,
      rtcConstraints: {
        audio: true,
      },
    });
  };

  const handlePracticeCallHangup = () => {
    setPracticeCallDialed(false);
    twilioDevice.disconnectAll();
  };

  function formatDateToCustomString(dateString) {
    if (dateString) {
      const originalDate = new Date(dateString);
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Intl.DateTimeFormat('en-US', options).format(originalDate);
    } else {
      return '';
    }
  }

  const handleNotInterested = () => {
    setObjSelected(false);
    setSubObjSelected(false);
    // if (objSelected && subObjSelected) {
    //     setSubObjSelected(false)
    // } else if (objSelected && !subObjSelected) {
    //     setObjSelected(false)
    // }
  };

  function displayFormattedDate(elementId) {
    const dateElement = document.getElementById(elementId);
    if (dateElement) {
      const currentDate = new Date();
      const options = { weekday: 'long', month: 'long', day: 'numeric' };
      const formattedDate = currentDate.toLocaleDateString('en-US', options);
      dateElement.innerHTML = formattedDate;
    }
  }

  function displayFormattedTime(elementId) {
    const timeElement = document.getElementById(elementId);
    if (timeElement) {
      const currentTime = new Date();
      const formattedTime = currentTime.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: '2-digit',
      });
      timeElement.innerHTML = formattedTime;
    }
  }

  const [initialPrompt, setInitialPrompt] = useState('');

  useEffect(() => {
    document.title = 'AccelerateIQ - Start Calling';
    // Your function to be executed
    const yourFunction = () => {
      displayFormattedDate('currentDate');
      displayFormattedTime('currentTime');
    };
    // Set interval to execute your function every one minute (60000 milliseconds)
    const intervalId = setInterval(yourFunction, 60000);
    // Cleanup function to clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    displayFormattedDate('currentDate');
    displayFormattedTime('currentTime');
    // Get caller Scripts
    fetch('/api/script/list-scripts', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      if (response.status === 200) {
        response.json().then((data) => {
          setCallerScripts(data.scripts);
          if (!scriptSelected) {
            setTimeout(() => setIsScriptBubbleUp(true), 500);
          }
        });
      } else if (response.status === 404) {
        setCallerScripts([]);
        setScriptSelected(false);
        setTimeout(() => setIsScriptBubbleUp(true), 500);
      }
    });
  }, [triggerCallerScipts]);

  useEffect(() => {
    if (!initialPrompt) {
      return;
    }
    setChatgptResponse('');
    const promptText = `${initialPrompt}. Here is my current script: ${scriptEdit.ScriptDescription}`;

    console.log('Prompt', promptText);
    // fetch ChatGPT response from the backend
    fetch('/api/v1/chatgpt', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        message: promptText,
      }),
    }).then((response) => {
      if (response.status === 200) {
        response.json().then((data) => {
          console.log(data);
          setChatgptResponse(data.answer);
        });
      } else if (response.status === 500) {
        // Perform Ajax call again for 500 error
        fetch('/api/v1/chatgpt', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            message: promptText,
          }),
        }).then((resp) => {
          if (resp.status === 200) {
            resp.json().then((respData) => {
              setChatgptResponse(respData.answer);
            });
          }
        });
      }
    });
  }, [scriptEdit, initialPrompt]);

  const formatWebsiteUrl = (url) => {
    if (url === null || url === undefined || url === '') {
      url = ''; // Set url to an empty string
    } else if (!url.includes('https://') && !url.includes('http://')) {
      // If url doesn't start with 'https://' or 'http://', prepend 'https://'
      url = `https://${url}`;
    }
    return url;
  };

  const PagerSetter = async (data) => {
    if (!data || !data['callerDetails'] || !data['callerDetails'].length) {
      console.error('Error: Unable to access callerDetails from data.');
      return;
    }
    var url = data['callOptions'][0].Website;
    // Check if url is null, undefined, or an empty string
    if (url === null || url === undefined || url === '') {
      url = ''; // Set url to an empty string
    } else if (!url.includes('https://') && !url.includes('http://')) {
      // If url doesn't start with 'https://' or 'http://', prepend 'https://'
      // url = `https://${url}`;
      url = `${url}`;
    }

    setFormObjContact({
      leadid: data['callOptions'][0].LeadId,
      company: data['callOptions'][0].CompanyName,
      contact: data['callOptions'][0].ContactName,
      title: data['callOptions'][0].Title,
      industry: data['callOptions'][0].Industry,
      time: data['callOptions'][0].LeadTimeZone,
      address: data['callOptions'][0].Address_1,
      city: data['callOptions'][0].City,
      state: data['callOptions'][0].State,
      contactId: data['callOptions'][0].ContactId,
      website: url,
      linkedin: data['callOptions'][0].LinkedInProfile,
      email: data['callOptions'][0].Email,
      zip: data['callOptions'][0].ZipCode,
      apptDate: moment(pickerInfo).format('MMMM Do YYYY'),
      apptTime: `${timeInfo} EST`,
      apptType: 'First Appointment - 30 Minutes',
      bestPhone: data['callOptions'][0].BestPhoneNumber,
      userId: data['callerDetails'][0].UserId,
    });
    setTopBar({
      campaign: data['callOptions'][0].CampaignShortName,
      callCategory: data['callOptions'][0].CallCategory,
      leadId: data['callOptions'][0].LeadId,
      callDate: data['callOptions'][0].LastDialed
        ? moment(data['callOptions'][0].LastDialed).format('MMMM DD, YYYY')
        : '',
    });
    setFormObjNotes({
      callNotes: data['callOptions'][0].CallerNotes,
      leadId: data['callOptions'][0].LeadId,
      tenantId: data['callOptions'][0].TenantID,
    });
    setFormCallNotes(data['callOptions'][0].CallerNotes);
    setPageText({
      script: data['callOptions'][0].Script,
      offer: data['callOptions'][0].Offer,
      voicemail: data['callOptions'][0].VoiceMail,
    });
    setScriptText(data['callOptions'][0].Script);

    const contactNameArray = data['callOptions'][0].ContactName.split(' '); // Splitting the string by space
    const firstName = contactNameArray[0];
    let companyDetailLinkedIn = '',
      companyDetailGoogle = '';

    try {
      const linkeninRidResp = await fetch('/api/v1/linkedin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          linkedin_url: data['callOptions'][0].LinkedInProfile,
        }),
      });

      if (linkeninRidResp.ok) {
        const linkedin_rid = await linkeninRidResp.json();
        console.log(linkedin_rid);
        try {
          const linkedinDataResp = await fetch('/api/v1/storage', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              rid: linkedin_rid.rid,
            }),
          });
          if (linkedinDataResp.ok) {
            const linkedinData = await linkedinDataResp.json();
            console.log('LinkedIn', linkedinData);
            companyDetailLinkedIn = linkedinData.description;
          }
        } catch (error) {
          companyDetailLinkedIn = '';
        }
        companyDetailLinkedIn = '';
      }
    } catch (error) {
      companyDetailLinkedIn = '';
    }

    try {
      const googleDataResp = await fetch('/api/v1/google', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          company: data['callerDetails'][0].CompanyName,
        }),
      });
      if (googleDataResp.ok) {
        const googleData = await googleDataResp.json();
        console.log('Google', googleData);
        companyDetailGoogle = googleData.body.searchResults[0].description;
      }
    } catch (error) {
      companyDetailGoogle = '';
    }

    setInitialPrompt(
      `I have a phone call with ${data['callOptions'][0].ContactName} and here is the company's linkedin profile ${companyDetailLinkedIn}. and this is google search result: ${companyDetailGoogle}. I am going to try to convince ${firstName} to schedule a sales appointment with me and I want the first paragraph of my pitch to include something that might be of interest to ${firstName} based on his LinkedIn profile. I also want the second paragraph of my script to reference a recent news item about ${data['callOptions'][0].CompanyName}. Please search the internet for the most recent news story about ${data['callOptions'][0].CompanyName} and insert something about the news story in the second paragraph of my script. Include a reference to one of ${data['callOptions'][0].CompanyName} products in the last paragraph of my script and the benefit ${firstName} will get from working with ${data['callerDetails'][0].CompanyName} in relation to that ${data['callOptions'][0].CompanyName} product. Please adapt my current script to include these items. Please return answers not extra words like 'here is an adapted script for...' or 'Feel free to modify the script...'. `
    );

    const promptText = `I have a phone call with ${data['callOptions'][0].ContactName} and here is the company's linkedin profile ${companyDetailLinkedIn}. and this is google search result: ${companyDetailGoogle}. I am going to try to convince ${firstName} to schedule a sales appointment with me and I want the first paragraph of my pitch to include something that might be of interest to ${firstName} based on his LinkedIn profile. I also want the second paragraph of my script to reference a recent news item about ${data['callOptions'][0].CompanyName}. Please search the internet for the most recent news story about ${data['callOptions'][0].CompanyName} and insert something about the news story in the second paragraph of my script. Include a reference to one of ${data['callOptions'][0].CompanyName} products in the last paragraph of my script and the benefit ${firstName} will get from working with ${data['callerDetails'][0].CompanyName} in relation to that ${data['callOptions'][0].CompanyName} product. Please adapt my current script to include these items. Please return answers not extra words like 'here is an adapted script for...' or 'Feel free to modify the script...'. Here is my current script: ${scriptEdit.ScriptDescription}`;

    console.log('Prompt', promptText);
    // fetch ChatGPT response from the backend
    fetch('/api/v1/chatgpt', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        message: promptText,
      }),
    }).then((response) => {
      if (response.status === 200) {
        response.json().then((data) => {
          console.log(data);
          setChatgptResponse(data.answer);
        });
      }
    });
  };

  const [callerTotalConnectionRate, setCallerTotalConnectionRate] = useState(0);
  // Fetch contact, lead information
  useEffect(() => {
    fetch('/call-options', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        CallerTimeZone: getTimezoneAbbreviation(),
      }),
    }).then((r) => {
      if (r.ok) {
        r.json().then((data) => {
          console.log(data);
          PagerSetter(data);
          setIndex(0);
          setClicked(false);
          setLeadLoaded(true);
          // setHangupClicked(false)
        });
      } else if (r.status === 404) {
        setLeadLoaded(false);
        if (trialModalOpen) {
          noLeadModalPending = true;
        } else {
          handleOpenOutOfLeadModal();
        }
      } else if (r.status === 401) {
        window.location.href = '/login';
      } else if (r.status === 302) {
        window.location.href = '/mdashboard';
      } else {
        // toast.error('Something went wrong!', {
        //     position: "top-right",
        //     autoClose: 3000, // Auto close the notification after 3 seconds
        // });
      }
    });
  }, [triggerCallOptions]);

  useEffect(() => {
    fetch('/api/v1/pitch/objections', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((r) => {
      if (r.ok) {
        r.json().then((data) => {
          console.log(data); // MainObjection, MainObjectionResponse
          setMainObjectionList(data.objections);
        });
      } else {
        // toast.error('Objections fetch failed!', {
        //     position: "top-right",
        //     autoClose: 3000, // Auto close the notification after 3 seconds
        // });
      }
    });
  }, []);

  useEffect(() => {
    // Fetch call metrics
    const today = moment();
    const thisMonthStart = today.clone().startOf('month').format('YYYY-MM-DD');
    const thisMonthEnd = today.clone().endOf('month').format('YYYY-MM-DD');
    fetch('/cdashboard', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        startDate: thisMonthStart,
        endDate: thisMonthEnd,
        reportType: 'All',
      }),
    }).then((response) => {
      if (response.status === 200) {
        response.json().then((data) => {
          console.log('Call metrics', data);
          const conPerc = data?.dashboardData?.query2?.[0]?.ConnectionPerc ?? 0;
          const totalDials = data?.dashboardData?.query2?.[0]?.TotalDials ?? 0;
          setCallerTotalConnectionRate((conPerc * 100).toFixed(0));
          localStorage.setItem('totalDials', totalDials);
        });
      }
    });
  }, []);

  const handleClickEntryIcon = (entry) => {
    setEntryMode(entry);
    setTabIndex(1); // set the script tab active
    setSeeTipsClicked(false); // show see tips for improvement for Pointers section
  };
  const [callRecList, setCallRecList] = useState([]);

  useEffect(() => {
    fetch('/call-recordings', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      // body: JSON.stringify({})
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data.callRecordings);
        setCallRecList(data.callRecordings.query1);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, [triggerRecList]);

  // when component unmound
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
      setTimeout(() => {
        if (clicked) {
          console.log('finish call now ...');
          handleDialClick();
        }
      }, 1000);
    };
  }, []);

  const emptyEntryModeArray = [
    {
      label: 'Playback',
      icon: <PlayIcon />,
      onClick: () => handleClickEntryIcon('Playback'),
    },
    {
      label: 'Pitch',
      icon: <FileIcon />,
      onClick: () => {
        handleClickEntryIcon('Pitch');
        setTimeout(() => setIsScriptBubbleUp(true), 500);
      },
    },
    {
      label: 'Pointers',
      icon: <BulbIcon />,
      onClick: () => handleClickEntryIcon('Pointers'),
    },
    {
      label: 'Practice',
      icon: <ClockIcon />,
      onClick: () => handleClickEntryIcon('Practice'),
    },
  ];

  return (
    <ManagerDashboard websiteUrl={websiteUrl} twilioDevice={twilioDevice}>
      <ToastContainer />
      {index === 5 && happenedIndex === 0 && (
        <div className={`pointer-events-none fixed inset-0 z-20 ${isPlayer}`}>
          <Player
            src={
              'https://lottie.host/43ca7dd8-6b2c-4fae-b8cb-7b9a6e25c4e8/uqvEeg4Nc5.json'
            }
            autoplay
            loop
          />
        </div>
      )}{' '}
      <div className='flex flex-wrap items-start justify-between gap-4'>
        {/* <!-- Topbar content  --> */}
        <div className=''>
          <div className='flex flex-wrap gap-x-10 gap-y-1'>
            <h2
              id='currentDate'
              className='text-2xl font-medium lg:text-3xl 2xl:text-4xl'
            >
              Wednesday, September 25
            </h2>
            <h2
              id='currentTime'
              className='text-2xl font-medium lg:text-3xl 2xl:text-4xl'
            >
              9:05 AM
            </h2>
          </div>
        </div>
        {/* <!-- Buttons --> */}
        <div className='flex items-center gap-4'></div>
      </div>
      {/* <!-- Tab contents --> */}
      <div className='mt-2 h-mt-6 grid flex-1 grid-cols-1 gap-6 xl:grid-cols-2 xl:gap-10 '>
        <div className='flex flex-col relative rounded-2xl bg-secondary/10 p-3 2xl:p-10'>
          <div className='flex flex-wrap items-center justify-between gap-2 border-b-[3px] border-secondary/20 pb-2'>
            <div className='relative flex items-center gap-2 before:absolute before:-bottom-[11px] before:left-0 before:h-[3px] before:w-[calc(100%+.5rem)] before:bg-secondary'>
              <span
                className='rounded-lg bg-accent-1 p-1 text-primary cursor-pointer transition-transform duration-300 ease-in-out hover:scale-125'
                onClick={() => setEntryMode('')}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  className='w-6 h-6'
                  viewBox='0 0 24 24'
                  stroke-width='2'
                  stroke='currentColor'
                >
                  <path
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    d='M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z'
                  />
                </svg>
              </span>
              <h6 className='text-lg font-medium'>Conversion Lab</h6>
            </div>
            {entryDetails.map(
              ({ mode, icon, label }) =>
                entryMode === mode && (
                  <div className='flex items-center' key={mode}>
                    <span className='inline-block w-8 h-8 items-center justify-center'>
                      {icon}
                    </span>
                    <span className='ml-2 text-accent-2'>{label}</span>
                  </div>
                )
            )}
          </div>
          <div
            className='mt-10 mx-4 mb-6 relative h-full min-h-[360px] overflow-hidden rounded-2xl bg-white/50 p-6 2xl:p-10'
            id='scroll'
          >
            <div className='mt-0 flex h-full flex-col gap-4'>
              {entryMode === '' && (
                <div className='items-center h-full flex flex-col justify-center'>
                  <div className='grid grid-cols-2 gap-20 2xl:gap-20 text-center'>
                    {emptyEntryModeArray.map(({ label, icon, onClick }) => (
                      <div
                        key={label}
                        className='flex flex-col cursor-pointer items-center justify-center group'
                        onClick={onClick}
                      >
                        <span className='relative transition-transform duration-300 ease-in-out hover:scale-125 hover:opacity-50 inline-flex w-24 h-24 2xl:w-28 2xl:h-28 flex items-center justify-center'>
                          {icon}
                          <span className='absolute inset-0 flex items-center justify-center text-white text-xl font-bold opacity-0 group-hover:opacity-100'>
                            {label}
                          </span>
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {entryMode === 'Pitch' && (
                <>
                  <div className='border-b border-gray-200'>
                    <nav
                      className='flex space-x-4 lg:space-x-8'
                      aria-label='Tabs'
                      role='tablist'
                    >
                      <button
                        type='button'
                        className={`${
                          tabIndex !== 3 && 'active'
                        } flex-1 inline-flex justify-center items-center gap-x-2 whitespace-nowrap border-b-2 border-transparent px-1 py-4 font-medium text-primary/40 transition hover:text-primary focus:text-secondary focus:outline-none disabled:pointer-events-none disabled:opacity-50 hs-tab-active:border-secondary hs-tab-active:text-secondary`}
                        id='todays-stats-tab-btn'
                        data-hs-tab='#todays-stats-tab'
                        aria-controls='todays-stats-tab'
                        onClick={() => {
                          setTabIndex(1);
                          setTimeout(() => setIsScriptBubbleUp(true), 500);
                          setIsBubbleUp(false);
                        }}
                        role='tab'
                      >
                        Script
                      </button>
                      <button
                        type='button'
                        className={`${
                          tabIndex === 3 && 'active'
                        } flex-1 inline-flex justify-center items-center gap-x-2 whitespace-nowrap border-b-2 border-transparent px-1 py-4 font-medium text-primary/40 transition hover:text-primary focus:text-secondary focus:outline-none disabled:pointer-events-none disabled:opacity-50 hs-tab-active:border-secondary hs-tab-active:text-secondary`}
                        id='this-month-tab-btn'
                        data-hs-tab='#this-month-tab'
                        aria-controls='this-month-tab'
                        onClick={() => {
                          setTabIndex(3);
                          setIsScriptBubbleUp(false);
                          setTimeout(() => setIsBubbleUp(true), 500);
                        }}
                        role='tab'
                      >
                        Objections
                      </button>
                    </nav>
                  </div>
                  {/* Scripts tab */}
                  <div
                    className={`${
                      tabIndex !== 3 ? 'block' : 'hidden'
                    } max-h-[340px] ${
                      isScriptBubbleUp || tabIndex === 2 || scriptSelected
                        ? 'overflow-y-auto'
                        : 'overflow-y-none'
                    } ${
                      callerScripts.length
                        ? ''
                        : 'items-center justify-center flex h-full'
                    } [&::-webkit-scrollbar-thumb]:bg-secondary/80 [&::-webkit-scrollbar-track]:bg-secondary/20 [&::-webkit-scrollbar]:w-2`}
                  >
                    {tabIndex === 1 &&
                      !scriptSelected &&
                      callerScripts.map((item, index) => (
                        <div
                          key={index}
                          className={`flex min-w-24 items-center justify-between gap-x-2 rounded-full bg-secondary/10 p-2 text-center text-sm 2xl:text-base font-medium leading-none text-accent-2 transition-colors focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50 md:min-w-32 2xl:text-base mr-2 mb-5 cursor-pointer transition-all duration-1000 ${
                            isScriptBubbleUp
                              ? 'translate-y-0 opacity-100'
                              : 'translate-y-full opacity-0'
                          }`}
                          style={{
                            transition: `transform ${1.5 - index * 0.1}s ${
                              index * 0.2
                            }s ease-out, opacity ${1.5 - index * 0.1}s ${
                              index * 0.2
                            }s ease-in-out`,
                          }}
                        >
                          <span className='flex'>{item.ScriptName}</span>
                          <span
                            className='flex cursor-pointer items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-accent-2 px-2 py-1 text-center font-medium text-white transition hover:border-primary hover:bg-primary hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50 md:px-4 md:text-lg'
                            onClick={() => {
                              setTimeout(
                                () => handleOpenOptimizeScriptModal(),
                                2800
                              );
                              setIsScriptBubbleUp(false);
                              setScriptSelected(true);
                              setScriptEdit(item);
                            }}
                          >
                            Select
                          </span>
                        </div>
                      ))}
                    {tabIndex === 1 &&
                      !scriptSelected &&
                      !callerScripts.length && (
                        <div
                          className={`flex w-48 items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-transparent p-3 text-center text-lg 2xl:text-xl font-medium leading-none text-accent-2 transition-colors hover:border-accent-2 hover:bg-accent-2 hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50 mr-2 mb-5 cursor-pointer transition-all duration-1000 ${
                            isScriptBubbleUp
                              ? 'translate-y-0 opacity-100'
                              : 'translate-y-full opacity-0'
                          }`}
                          onClick={() => {
                            if (!scriptSelected) {
                              setScriptEdit({
                                ScriptName: '',
                                ScriptDescription: '',
                                DefaultScript: 0,
                              });
                            }
                            setIsScriptBubbleUp(false);
                            handleOpenScriptEditModal();
                          }}
                          style={{
                            transition: `transform ${1.5 - index * 0.1}s ${
                              index * 0.2
                            }s ease-out, opacity ${1.5 - index * 0.1}s ${
                              index * 0.2
                            }s ease-in-out`,
                          }}
                        >
                          <span className='text-center'>
                            Add your first script
                          </span>
                        </div>
                      )}
                    {tabIndex === 1 && scriptSelected && (
                      <div>
                        <p className='whitespace-preline text-base font-medium lg:text-lg'>
                          {scriptEdit.ScriptDescription}
                        </p>
                      </div>
                    )}
                    <div className={`${tabIndex === 2 ? 'block' : 'hidden'}`}>
                      {tabIndex === 2 && chatgptResponse && (
                        <ScriptTab words={chatgptResponse} />
                      )}
                    </div>
                    <button
                      type='button'
                      className={`${
                        tabIndex === 1 && callerScripts.length
                          ? 'block'
                          : 'hidden'
                      } absolute bottom-3 right-3 flex-1 inline-flex justify-center items-center gap-x-2 whitespace-nowrap border-b-2 border-transparent px-1 py-4 font-medium text-secondary transition hover:text-primary focus:text-secondary focus:outline-none z-10`}
                      onClick={() => {
                        if (!scriptSelected) {
                          setScriptEdit({
                            ScriptName: '',
                            ScriptDescription: '',
                            DefaultScript: 0,
                          });
                        }
                        handleOpenScriptEditModal();
                      }}
                      id='edit-script-btn'
                    >
                      {scriptSelected ? 'Edit Script' : 'Add Script'}
                    </button>
                  </div>
                  {/* Objections tab */}
                  <div
                    className={`${tabIndex === 3 ? 'block' : 'hidden'} ${
                      objSelected &&
                      !subObjSelected &&
                      subObjectionsList.length > 0
                        ? 'h-full flex flex-col jusstify-around'
                        : ''
                    }`}
                  >
                    {objSelected && gptObjectionResponse && (
                      <ScriptTab words={gptObjectionResponse} />
                    )}
                    {objSelected &&
                      !subObjSelected &&
                      subObjectionsList.length > 0 &&
                      subObjectionsList[0].SubObjection !== null && (
                        <div className='justify-center items-center h-full flex flex-row text-center mt-5'>
                          {subObjectionsList.map((item, index) => (
                            <div
                              key={index}
                              className={`cursor-pointer flex flex-col inline-flex min-w-24 items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-transparent px-2 py-1 text-center text-sm 2xl:text-base font-medium leading-none text-accent-2 transition-colors hover:border-accent-2 hover:bg-accent-2 hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50 md:min-w-32 md:px-4 md:py-2 2xl:text-base mr-2 mb-5 transition-all duration-1000 ${
                                isSubBubbleUp
                                  ? 'translate-y-0 opacity-100'
                                  : 'translate-y-full opacity-0'
                              }`}
                              style={{
                                transition: `transform ${3 - index * 0.1}s ${
                                  index * 0.2
                                }s ease-out, opacity ${3 - index * 0.1}s ${
                                  index * 0.2
                                }s ease-in-out`,
                              }}
                              onClick={() => {
                                setIsGPTForObj(false);
                                handleSubObjectionSelect(item);
                                handleGetGPTResponse(
                                  item.SubObjectionResponse,
                                  ''
                                );
                              }}
                            >
                              {item.SubObjection}
                            </div>
                          ))}
                        </div>
                      )}
                  </div>
                  <div
                    className={`${
                      tabIndex === 3
                        ? 'flex h-full items-center block grid grid-cols-2 gap-0 h-gap-3'
                        : 'hidden'
                    }`}
                  >
                    {!objSelected &&
                      mainObjectionList.map((item, index) => (
                        <div
                          key={index}
                          className={`flex flex-col inline-flex min-w-24 items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-transparent p-3 text-center text-sm 2xl:text-base font-medium leading-none text-accent-2 transition-colors hover:border-accent-2 hover:bg-accent-2 hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50 md:min-w-32 2xl:text-base mr-2 mb-5 cursor-pointer transition-all duration-1000 ${
                            isBubbleUp
                              ? 'translate-y-0 opacity-100'
                              : 'translate-y-full opacity-0'
                          }`}
                          style={{
                            transition: `transform ${1.5 - index * 0.1}s ${
                              index * 0.2
                            }s ease-out, opacity ${1.5 - index * 0.1}s ${
                              index * 0.2
                            }s ease-in-out`,
                          }}
                          onClick={() => {
                            setIsGPTForObj(false);
                            handleObjectionSelect(item);
                            handleGetGPTResponse(
                              item.MainObjectionResponse,
                              item.MainObjection
                            );
                            setTimeout(() => setIsSubBubbleUp(true), 2000);
                          }}
                        >
                          {item.MainObjection}
                        </div>
                      ))}
                  </div>
                </>
              )}
              {entryMode === 'Pointers' && (
                <PointerStats companyWebSite={formObjContact.website} />
              )}
              {entryMode === 'Practice' && (
                <Practice
                  initiatePracticeCall={initiatePracticeCall}
                  setPracticeCallDialed={setPracticeCallDialed}
                  practiceCallDialed={practiceCallDialed}
                />
              )}
              {entryMode === 'Playback' && (
                <Playback callRecList={callRecList} />
              )}
            </div>
          </div>
          <div className='grid grid-cols-4 flex flex-col items-center 2xl:h-12 xl:h-9 conv-lab-bottom-bar'>
            <a
              onClick={() => {
                if (entryMode === 'Pitch' && objSelected) {
                  setSubObjSelected(false);
                  setObjSelected(false);
                  setIsGPTForObj(false);
                  // setIsBubbleUp(false);
                  setIsSubBubbleUp(false);
                } else if (tabIndex === 1 && scriptSelected) {
                  setScriptSelected(false);
                  setTimeout(() => setIsScriptBubbleUp(true), 500);
                } else if (tabIndex === 2) {
                  setTabIndex(1);
                } else {
                  setEntryMode('');
                  setIsScriptBubbleUp(false);
                }
              }}
              class={`inline-flex grid-col items-center cursor-pointer gap-x-2 text-sm text-gray-800 transition hover:text-secondary ${
                entryMode !== '' ? '' : 'invisible'
              }`}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                stroke='currentColor'
                stroke-width='1.5'
                class='h-4 w-4'
                viewBox='0 0 24 24'
              >
                <path
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  d='M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18'
                />
              </svg>
              Go Back
            </a>
            {entryMode === 'Pitch' && (
              <>
                <span
                  className={`${
                    tabIndex === 3 && objSelected ? '' : 'invisible'
                  } col-span-2 justify-center items-center cursor-pointer text-center text-lg font-bold text-accent-2`}
                  onClick={() => handleNotInterested()}
                >
                  {curObjection.MainObjection}
                </span>
                <div class='relative flex flex-col items-center group invisible'>
                  <button
                    type='button'
                    className={`${
                      entryMode === 'Pitch' &&
                      (tabIndex !== 1 || callerScripts.length)
                        ? 'block'
                        : 'hidden'
                    } w-full col-span-1 flex-1 inline-flex justify-end items-center border-transparent px-1 py-0 font-medium`}
                    id='lightning-bolt-obj-bottom-btn'
                    onClick={() => {
                      if (isGPTForObj && objSelected) {
                        setIsGPTForObj(false);
                      } else if (tabIndex === 1) {
                        setTabIndex(2);
                      } else if (tabIndex === 2) {
                        setTabIndex(1);
                      } else if (!isGPTForObj && objSelected) {
                        setIsGPTForObj(true);
                        handleGetGPTResponse(
                          subObjSelected
                            ? curSubObjection.SubObjectionResponse
                            : curObjection.MainObjectionResponse,
                          subObjSelected ? '' : curObjection.MainObjection
                        );
                      }
                    }}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='currentColor'
                      viewBox='0 0 24 24'
                      stroke-width='1.5'
                      stroke='currentColor'
                      class='xl:w-9 xl:h-9 2xl:w-12 2xl:h-12 text-yellow-400'
                    >
                      <path
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        d='m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z'
                      />
                    </svg>
                  </button>
                </div>
              </>
            )}
            {entryMode === 'Practice' && (
              <>
                <span
                  className={`col-span-2 flex cursor-pointer items-center justify-center rounded-full ${
                    practiceCallDialed ? '' : 'hidden'
                  }`}
                  style={{ backgroundColor: 'rgb(222, 234, 245)' }}
                  onClick={() => {
                    handlePracticeCallHangup();
                  }}
                >
                  <img
                    src='/hangup.png'
                    alt='img-practice-hangup'
                    class='rounded-full h-10 w-10'
                  />
                </span>
              </>
            )}
            {entryMode === 'Playback' && (
              <div
                className={`${
                  entryMode === 'Playback' ? 'block' : 'hidden'
                } col-span-3 flex-1 inline-flex justify-end items-center border-transparent px-1 py-0 font-medium`}
                id='call-recording-search-btn'
              >
                <CallSearchBox setCallRecList={setCallRecList} />
              </div>
            )}
          </div>
        </div>
        {/* <!-- Call center --> */}
        <div
          className={`${
            index === 0
              ? 'flex flex-col rounded-2xl bg-secondary/10 p-3 2xl:p-10'
              : 'hidden'
          }`}
        >
          <div className='flex flex-wrap items-center justify-between gap-2 border-b-[3px] border-secondary/20 pb-2'>
            <div className='relative flex items-center gap-2 before:absolute before:-bottom-[13px] before:left-0 before:h-[3px] before:w-[calc(100%+.5rem)] before:bg-secondary'>
              <CallIcon className='w-5 h-5' />
              <h6 className='text-lg font-medium'>Call Center</h6>
            </div>
            <div className={`flex`}>
              <button
                onClick={handleOpenSearchModal}
                data-hs-overlay='#search-modal'
                className='inline-flex h-7 w-7 items-center justify-center p-1 text-accent-2'
              >
                <svg
                  viewBox='0 0 33 34'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M32.2665 30.895L24.4939 23.124C26.7468 20.4193 27.8701 16.9503 27.6304 13.4385C27.3906 9.92665 25.8062 6.64247 23.2066 4.26911C20.6071 1.89575 17.1927 0.615941 13.6736 0.695918C10.1545 0.775894 6.80177 2.2095 4.31276 4.6985C1.82376 7.18751 0.390152 10.5403 0.310175 14.0594C0.230199 17.5784 1.51001 20.9929 3.88337 23.5924C6.25673 26.1919 9.54091 27.7763 13.0527 28.0161C16.5645 28.2559 20.0336 27.1325 22.7382 24.8797L30.5093 32.6523C30.6247 32.7677 30.7617 32.8592 30.9124 32.9216C31.0632 32.9841 31.2247 33.0162 31.3879 33.0162C31.5511 33.0162 31.7127 32.9841 31.8634 32.9216C32.0142 32.8592 32.1512 32.7677 32.2665 32.6523C32.3819 32.5369 32.4735 32.3999 32.5359 32.2492C32.5983 32.0984 32.6305 31.9368 32.6305 31.7737C32.6305 31.6105 32.5983 31.4489 32.5359 31.2982C32.4735 31.1474 32.3819 31.0104 32.2665 30.895ZM2.82471 14.3874C2.82471 12.1768 3.48022 10.0158 4.70836 8.1778C5.93649 6.33977 7.68209 4.9072 9.7244 4.06124C11.7667 3.21529 14.014 2.99395 16.1821 3.42521C18.3502 3.85648 20.3418 4.92097 21.9049 6.48409C23.468 8.04721 24.5325 10.0387 24.9638 12.2069C25.395 14.375 25.1737 16.6223 24.3277 18.6646C23.4818 20.7069 22.0492 22.4525 20.2112 23.6806C18.3731 24.9088 16.2122 25.5643 14.0016 25.5643C11.0383 25.561 8.19735 24.3824 6.10198 22.287C4.00662 20.1916 2.828 17.3507 2.82471 14.3874Z'
                    fill='#0F0F74'
                  />
                </svg>
              </button>
            </div>
          </div>
          {/* <!-- Contact information --> */}
          <div className='h-full flex flex-col justify-between mt-10 mx-4 mb-6'>
            <div
              className={`rounded-2xl bg-white/50 p-3 ${
                contactExpanded ? 'hidden' : 'block'
              }`}
            >
              <div className='flex flex-wrap justify-between'>
                <h6 className='text-lg font-medium'>Campaign Details</h6>
                <div className='flex text-base 2xl:text-lg font-medium'>
                  Lead ID: &nbsp;
                  <span className='text-accent-2'>
                    {' '}
                    {topBar.leadId ?? '_'}{' '}
                  </span>
                </div>
              </div>
              <div className='flex flex-col gap-3 mt-3'>
                <div className='text-base 2xl:text-lg font-medium'>
                  Campaign: &nbsp;
                  <span className='text-accent-2'>
                    {' '}
                    {topBar.campaign ?? '_'}{' '}
                  </span>
                </div>
                <div className='text-base 2xl:text-lg font-medium'>
                  Call Category: &nbsp;
                  <span className='text-accent-2'>
                    {topBar.callCategory === 'OB_InitialReach'
                      ? 'Initial Reach'
                      : topBar.callCategory === 'OB_Callback'
                      ? 'Callback'
                      : topBar.callCategory ?? '_'}
                  </span>
                </div>
                <div className='text-base 2xl:text-lg font-medium'>
                  Last Dialed: &nbsp;
                  <span className='text-accent-2'>
                    {' '}
                    {topBar.callDate || '_'}{' '}
                  </span>
                </div>
              </div>
            </div>
            <div className='rounded-2xl bg-white/50 p-3'>
              <div className={`flex flex-wrap justify-between items-center `}>
                <h6 className='flex text-lg font-medium'>Contact Details</h6>
                <div className={`flex`}>
                  <button
                    onClick={handleOpenEditContactModal}
                    data-hs-overlay='#edit-contact-modal'
                    type='button'
                    className='flex h-8 w-8 items-center justify-center rounded-lg border border-transparent bg-transparent text-accent-2 transition hover:bg-accent-2/10 focus:outline-none focus:ring focus:ring-accent-2/20 disabled:pointer-events-none disabled:opacity-50'
                    disabled={!leadLoaded}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 18 18'
                      fill='currentColor'
                      className='h-4 w-4'
                    >
                      <path d='m14.4 7.62-4-4 2.16-2.16 4 4Zm-8.94 8.94h-4v-4l7.9-7.94 4 4ZM17.58 4.44l-4-4a1.6 1.6 0 0 0-.47-.31 1.5 1.5 0 0 0-.57-.13 1.46 1.46 0 0 0-.54.11 1.38 1.38 0 0 0-.47.31L.42 11.52a1.38 1.38 0 0 0-.31.48 1.46 1.46 0 0 0-.11.55v4A1.43 1.43 0 0 0 1.44 18h4a1.46 1.46 0 0 0 .56-.11 1.38 1.38 0 0 0 .47-.31l11.1-11.1a1.38 1.38 0 0 0 .32-.48 1.46 1.46 0 0 0 .11-.54 1.5 1.5 0 0 0-.11-.55 1.6 1.6 0 0 0-.31-.47Z' />
                    </svg>
                  </button>
                </div>
              </div>
              <p className='text-lg font-bold text-accent-2 mt-2'>
                {formObjContact.contact}
              </p>
              <p className='text-lg font-medium mt-2'>
                {formObjContact.title
                  ? formObjContact.title + ' at ' + formObjContact.company
                  : ''}
              </p>
              <div
                className={`mt-2 space-y-0 2xl:space-y-4 transform transition-all duration-500 ${
                  contactExpanded ? 'block' : 'hidden'
                }`}
              >
                <div className='relative'>
                  <input
                    type='text'
                    id='industry'
                    name='industry'
                    className='peer block w-full bg-transparent px-0 py-4 text-lg font-medium transition placeholder:text-transparent autofill:pb-2 autofill:pt-6 focus:border-x-transparent focus:border-b-secondary focus:border-t-transparent focus:pb-2 focus:pt-6 focus:outline-none focus:ring-0 disabled:pointer-events-none disabled:opacity-50 [&:not(:placeholder-shown)]:pb-2 [&:not(:placeholder-shown)]:pt-6'
                    placeholder='Industry'
                    value={formObjContact.industry}
                    readOnly
                  />
                  <label
                    htmlFor='industry'
                    className='pointer-events-none absolute start-0 top-0 flex h-full origin-top-left items-center truncate border border-transparent px-0 font-medium transition duration-100 ease-in-out peer-focus:-translate-y-3 peer-focus:scale-90 peer-focus:text-gray-500 peer-disabled:pointer-events-none peer-disabled:opacity-50 peer-[:not(:placeholder-shown)]:-translate-y-3 peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:text-gray-500'
                  >
                    Industry
                  </label>
                </div>
                <div className='relative'>
                  <input
                    type='text'
                    id='address'
                    name='address'
                    className='peer block w-full bg-transparent px-0 py-4 text-lg font-medium transition placeholder:text-transparent autofill:pb-2 autofill:pt-6 focus:border-x-transparent focus:border-b-secondary focus:border-t-transparent focus:pb-2 focus:pt-6 focus:outline-none focus:ring-0 disabled:pointer-events-none disabled:opacity-50 [&:not(:placeholder-shown)]:pb-2 [&:not(:placeholder-shown)]:pt-6'
                    placeholder='Contact Address'
                    value={formObjContact.address ?? '_'}
                    readOnly
                  />
                  <label
                    htmlFor='address'
                    className='pointer-events-none absolute start-0 top-0 flex h-full origin-top-left items-center truncate border border-transparent px-0 font-medium transition duration-100 ease-in-out peer-focus:-translate-y-3 peer-focus:scale-90 peer-focus:text-gray-500 peer-disabled:pointer-events-none peer-disabled:opacity-50 peer-[:not(:placeholder-shown)]:-translate-y-3 peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:text-gray-500'
                  >
                    Contact Address
                  </label>
                </div>
                <div className='grid grid-cols-3 gap-4 md:grid-cols-3 xl:grid-cols-3'>
                  <div className='relative'>
                    <input
                      type='text'
                      id='city'
                      name='city'
                      className='peer block w-full bg-transparent px-0 py-4 text-lg font-medium transition placeholder:text-transparent autofill:pb-2 autofill:pt-6 focus:border-x-transparent focus:border-b-secondary focus:border-t-transparent focus:pb-2 focus:pt-6 focus:outline-none focus:ring-0 disabled:pointer-events-none disabled:opacity-50 [&:not(:placeholder-shown)]:pb-2 [&:not(:placeholder-shown)]:pt-6'
                      placeholder='City'
                      value={formObjContact.city ?? '_'}
                      readOnly
                    />
                    <label
                      htmlFor='city'
                      className='pointer-events-none absolute start-0 top-0 flex h-full origin-top-left items-center truncate border border-transparent px-0 font-medium transition duration-100 ease-in-out peer-focus:-translate-y-3 peer-focus:scale-90 peer-focus:text-gray-500 peer-disabled:pointer-events-none peer-disabled:opacity-50 peer-[:not(:placeholder-shown)]:-translate-y-3 peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:text-gray-500'
                    >
                      City
                    </label>
                  </div>
                  <div className='relative'>
                    <input
                      type='text'
                      id='state'
                      name='state'
                      className='peer block w-full bg-transparent px-0 py-4 text-lg font-medium transition placeholder:text-transparent autofill:pb-2 autofill:pt-6 focus:border-x-transparent focus:border-b-secondary focus:border-t-transparent focus:pb-2 focus:pt-6 focus:outline-none focus:ring-0 disabled:pointer-events-none disabled:opacity-50 [&:not(:placeholder-shown)]:pb-2 [&:not(:placeholder-shown)]:pt-6'
                      placeholder='State'
                      value={formObjContact.state ?? '_'}
                      readOnly
                    />
                    <label
                      htmlFor='state'
                      className='pointer-events-none absolute start-0 top-0 flex h-full origin-top-left items-center truncate border border-transparent px-0 font-medium transition duration-100 ease-in-out peer-focus:-translate-y-3 peer-focus:scale-90 peer-focus:text-gray-500 peer-disabled:pointer-events-none peer-disabled:opacity-50 peer-[:not(:placeholder-shown)]:-translate-y-3 peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:text-gray-500'
                    >
                      State
                    </label>
                  </div>
                  <div className='relative'>
                    <input
                      type='text'
                      id='zipcode'
                      name='zip'
                      className='peer block w-full bg-transparent px-0 py-4 text-lg font-medium transition placeholder:text-transparent autofill:pb-2 autofill:pt-6 focus:border-x-transparent focus:border-b-secondary focus:border-t-transparent focus:pb-2 focus:pt-6 focus:outline-none focus:ring-0 disabled:pointer-events-none disabled:opacity-50 [&:not(:placeholder-shown)]:pb-2 [&:not(:placeholder-shown)]:pt-6'
                      placeholder='Zip Code'
                      value={formObjContact.zip ?? '_'}
                      readOnly
                    />
                    <label
                      htmlFor='zipcode'
                      className='pointer-events-none absolute start-0 top-0 flex h-full origin-top-left items-center truncate border border-transparent px-0 font-medium transition duration-100 ease-in-out peer-focus:-translate-y-3 peer-focus:scale-90 peer-focus:text-gray-500 peer-disabled:pointer-events-none peer-disabled:opacity-50 peer-[:not(:placeholder-shown)]:-translate-y-3 peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:text-gray-500'
                    >
                      Zip Code
                    </label>
                  </div>
                </div>
              </div>
              <div className='flex items-center justify-between mt-5'>
                <div className='flex items-center'>
                  <a
                    href={`mailto:${formObjContact.email}`}
                    name={'email'}
                    className={`flex h-8 w-8 items-center justify-center rounded-lg border border-transparent bg-transparent text-blue-600 transition hover:bg-accent-2/10 focus:outline-none focus:ring focus:ring-accent-2/20 ${
                      formObjContact.email
                        ? ''
                        : 'pointer-events-none opacity-50'
                    }`}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      className='h-5 w-5'
                    >
                      <path d='M21.2 8.4c.5.38.8.97.8 1.6v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V10a2 2 0 0 1 .8-1.6l8-6a2 2 0 0 1 2.4 0l8 6Z' />
                      <path d='m22 10-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 10' />
                    </svg>
                  </a>
                  <a
                    href={`${formObjContact.linkedin}`}
                    target='_blank'
                    name={'linkedin'}
                    className={`flex h-8 w-8 items-center justify-center rounded-lg border border-transparent bg-transparent text-blue-600 transition hover:bg-accent-2/10 focus:outline-none focus:ring focus:ring-accent-2/20 ${
                      formObjContact.linkedin
                        ? ''
                        : 'pointer-events-none opacity-50'
                    }`}
                    rel='noreferrer'
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 32 32'
                      fill='currentColor'
                      className='h-6 w-6'
                    >
                      <path d='M7.5 5A2.518 2.518 0 0 0 5 7.5v17C5 25.867 6.133 27 7.5 27h17c1.367 0 2.5-1.133 2.5-2.5v-17C27 6.133 25.867 5 24.5 5Zm0 2h17c.285 0 .5.215.5.5v17a.49.49 0 0 1-.5.5h-17a.489.489 0 0 1-.5-.5v-17c0-.285.215-.5.5-.5Zm2.938 1.719a1.719 1.719 0 1 0 0 3.437 1.719 1.719 0 0 0 0-3.437Zm9.03 4.562c-1.433 0-2.386.785-2.78 1.531h-.063V13.5h-2.813V23h2.938v-4.688c0-1.238.246-2.437 1.781-2.437 1.512 0 1.532 1.398 1.532 2.5V23H23v-5.219c0-2.554-.543-4.5-3.531-4.5ZM9 13.5V23h2.969v-9.5Z' />
                    </svg>
                  </a>
                  <a
                    href={`${formatWebsiteUrl(formObjContact.website)}`}
                    target='_blank'
                    name={'website'}
                    className={`flex h-8 w-8 items-center justify-center rounded-lg border border-transparent bg-transparent text-blue-600 transition hover:bg-accent-2/10 focus:outline-none focus:ring focus:ring-accent-2/20 ${
                      formObjContact.website
                        ? ''
                        : 'pointer-events-none opacity-50'
                    }`}
                    rel='noreferrer'
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      className='h-5 w-5'
                    >
                      <circle cx='12' cy='12' r='10' />
                      <path d='M12 2a14.5 14.5 0 0 0 0 20 14.5 14.5 0 0 0 0-20M2 12h20' />
                    </svg>
                  </a>
                </div>
                <span
                  className='flex font-medium cursor-pointer border border-transparent bg-transparent text-accent-2 transition hover:bg-accent-2/10 focus:outline-none focus:ring focus:ring-accent-2/20 disabled:pointer-events-none disabled:opacity-50'
                  onClick={() => {
                    if (contactExpanded) {
                      setContactExpanded(false);
                    } else {
                      setContactExpanded(true);
                    }
                  }}
                >
                  {contactExpanded ? 'Show less' : 'See more'}
                </span>
              </div>
            </div>
            <div
              className={`flex flex-wrap rounded-2xl bg-white/50 items-center justify-between rounded-2xl px-8 ${
                contactExpanded ? '2xl:py-10 xl:py-5' : '2xl:py-16 xl:py-8'
              }`}
            >
              <span
                onClick={() => setOpenDeclineModal(true)}
                data-hs-overlay='#discard-modal'
                className={`cursor-pointer rounded-lg bg-transparent-1 p-1 text-primary ${
                  clicked ? 'invisible' : ''
                }`}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='currentColor'
                  viewBox='0 0 256 256'
                  stroke-width='1.5'
                  stroke='currentColor'
                  width='32'
                  height='32'
                >
                  <path d='M216,48H40a8,8,0,0,0,0,16h8V208a16,16,0,0,0,16,16H192a16,16,0,0,0,16-16V64h8a8,8,0,0,0,0-16ZM192,208H64V64H192ZM80,24a8,8,0,0,1,8-8h80a8,8,0,0,1,0,16H88A8,8,0,0,1,80,24Z'></path>
                </svg>
              </span>
              <button
                onClick={() => handleDialClick()}
                id='dial-btn'
                className={`${
                  !hangupClicked || index === 0
                    ? 'inline-flex h-12 w-35 items-center justify-center gap-x-2 rounded-full border border-secondary bg-secondary px-6 py-2 text-center font-medium text-white transition hover:border-[#f4b610] hover:bg-[#f4b610] hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50 md:px-10 md:text-lg mx-5'
                    : 'md:px-10 md:text-lg mx-5 invisible'
                }`}
                disabled={
                  twilioDevice && leadLoaded && !isDialDisabled ? false : true
                }
              >
                {!loader ? (
                  <span className='btn-text'>
                    {' '}
                    {!clicked ? 'Dial' : 'End Call'}
                  </span>
                ) : (
                  <svg
                    id='loader'
                    className=' h-5 w-5 animate-spin text-white'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                  >
                    <circle
                      className='opacity-25'
                      cx='12'
                      cy='12'
                      r='10'
                      stroke='currentColor'
                      stroke-width='4'
                    ></circle>
                    <path
                      className='opacity-75'
                      fill='currentColor'
                      d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                    ></path>
                  </svg>
                )}
              </button>
              <span
                onClick={handleOpenCallNotesModal}
                data-hs-overlay='#call-notes-modal'
                className={`cursor-pointer rounded-lg p-1 text-primary ${
                  formCallNotes && formCallNotes !== 'null'
                    ? 'bg-secondary'
                    : 'bg-transparent-1'
                }`}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  fill='currentColor'
                  class='h-6 w-6'
                >
                  <path d='M17 17v3.59L20.59 17ZM2 22h13v-6a1 1 0 0 1 1-1h6V2H2ZM24 2v13.59a2 2 0 0 1-.15.76 1.92 1.92 0 0 1-.44.65L17 23.41a2 2 0 0 1-.65.44 2 2 0 0 1-.76.15H2a2 2 0 0 1-1.41-.59A2 2 0 0 1 0 22V2A2 2 0 0 1 .59.59 2 2 0 0 1 2 0h20a2 2 0 0 1 1.41.59A2 2 0 0 1 24 2ZM12 15H8a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2Zm-4-2h8a1 1 0 1 0 0-2H8a1 1 0 1 0 0 2ZM7 8a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2H8a1 1 0 0 1-1-1Z' />
                </svg>
              </span>
            </div>
          </div>
          {/*<!-- ModalBoxes  -->*/}
          <EditContactModal
            isOpen={openEditContactModal}
            onClose={handleCloseEditContactModal}
            formObjContact={formObjContact}
            setFormObjContact={setFormObjContact}
          />
          <SearchModal
            isOpen={isOpenSearchModal}
            onClose={handleCloseSearchModal}
            setSearchResults={setSearchResults}
            searchResults={searchResults}
            onFinish={handleFinish}
          />
          <CallNotesModal
            isOpen={openCallNotesModal}
            onClose={handleCloseCallNotesModal}
            formObjNotes={formObjNotes}
            setFormObjNotes={setFormObjNotes}
            setFormCallNotes={setFormCallNotes}
          />
          <DiscardModal
            isOpen={openDeclineModal}
            onClose={() => setOpenDeclineModal(false)}
            handleFinish={handleFinish}
            updateDialOutcomes={updateDialOutcomes}
            setHappenedIndex={setHappenedIndex}
          />
          <BasicModal
            open={openOutOfLeadModal}
            handleClose={handleCloseOutOfLeadModal}
            handleOpen={handleOpenOutOfLeadModal}
          >
            <div
              id='out-of-lead-modal'
              className='hs-overlay pointer-events-none z-[60]  h-full w-full overflow-y-auto overflow-x-hidden focus-visible:outline-none absolute left-[50%] top-[35%] -translate-x-[50%]'
            >
              <div className='m-3 mt-0  transition-all ease-out hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 sm:mx-auto sm:w-full sm:max-w-xl border-2 border-blue-900 border-solid rounded-xl border'>
                <div className='pointer-events-auto flex flex-col rounded-xl border bg-white shadow-sm'>
                  <button
                    onClick={handleCloseOutOfLeadModal}
                    className='inline-flex justify-end items-center border-transparent'
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke-width='1.5'
                      stroke='currentColor'
                      class='w-6 h-6'
                    >
                      <path
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        d='m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z'
                      />
                    </svg>
                  </button>
                  <div className='overflow-y-auto text-xl px-5 pb-5'>
                    <p>
                      You have no leads available to call during this hour.
                      Check back later, update your ICP or feel free to send a
                      message to{' '}
                      <a
                        className='text-secondary'
                        href='mailto:customersuccess@prospectiq.ai'
                      >
                        customersuccess@prospectiq.ai
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </BasicModal>
          <BasicModal
            open={showTenthDialModal}
            handleClose={handleCloseTenthModal}
            handleOpen={handleOpenTenthModal}
          >
            <div
              id='congrats-10thdial-modal'
              className='hs-overlay pointer-events-none z-[60] h-full w-full overflow-y-auto overflow-x-hidden focus-visible:outline-none absolute left-[50%] top-[15%] -translate-x-[50%]'
            >
              <div className='m-3 mt-0 transition-all ease-out hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 sm:mx-auto sm:w-full sm:max-w-xl border-2 border-blue-900 border-solid rounded-xl border'>
                <div className='pointer-events-auto p-5 flex flex-col rounded-xl border bg-white shadow-sm'>
                  <button
                    onClick={handleCloseTenthModal}
                    className='inline-flex justify-end items-center border-transparent'
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke-width='1.5'
                      stroke='currentColor'
                      class='w-6 h-6'
                    >
                      <path
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        d='m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z'
                      />
                    </svg>
                  </button>
                  <div className='overflow-y-auto text-xl px-5 pb-5'>
                    <p className='text-center text-2xl font-bold items-center'>
                      WOW! You have{' '}
                      <span className='text-blue-600'>
                        {callerTotalConnectionRate}%
                      </span>{' '}
                      connection rate
                    </p>
                    <p className='text-center text-2xl font-bold items-center'>
                      after only <span className='text-blue-600'>10</span>{' '}
                      dials!
                    </p>
                    <p className='text-center text-2xl font-bold items-center mt-5'>
                      Now that's something to celebrate!
                    </p>
                    <div
                      className='flex items-center justify-center'
                      style={{ marginTop: '-45px' }}
                    >
                      <img
                        className='h-48 w-48'
                        src='https://media.tenor.com/VgCDirag6VcAAAAi/party-popper-joypixels.gif'
                        alt='Party Popper Joypixels'
                      />
                    </div>
                    <p className='text-center text-2xl font-bold'>
                      Only <span className='text-red-500'>10</span> more dials
                      until trial ends!
                    </p>
                    <div className='flex justify-center items-center'>
                      <button
                        onClick={() => handleCloseTenthModal()}
                        className='inline-flex h-12 w-35 items-center justify-center gap-x-2 rounded-full border border-secondary bg-secondary px-6 py-2 text-center font-bold text-xl text-white transition hover:border-[#f4b610] hover:bg-[#f4b610] hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50 md:px-10 md:text-lg mx-5 mt-3'
                      >
                        Let's keep dialing!
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </BasicModal>
          <BasicModal
            open={show20thDialModal}
            handleClose={handleClose20thModal}
            handleOpen={handleOpen20thModal}
          >
            <div
              id='congrats-20th-dial-modal'
              className='hs-overlay pointer-events-none z-[60] h-full w-full overflow-y-auto overflow-x-hidden focus-visible:outline-none absolute left-[50%] top-[15%] -translate-x-[50%]'
            >
              <div className='m-3 mt-0 transition-all ease-out hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 sm:mx-auto sm:w-full sm:max-w-xl border-2 border-blue-900 border-solid rounded-xl border'>
                <div className='relative pointer-events-auto p-5 flex flex-col rounded-xl border bg-white shadow-sm'>
                  <div className={`pointer-events-none absolute inset-0 z-20`}>
                    <Player
                      src={
                        'https://lottie.host/43ca7dd8-6b2c-4fae-b8cb-7b9a6e25c4e8/uqvEeg4Nc5.json'
                      }
                      autoplay
                      loop
                    />
                  </div>
                  <div className='flex flex-col gap-5 overflow-y-auto text-xl px-5 py-5'>
                    <p className='text-center text-2xl font-bold items-center'>
                      CONGRATULATIONS!
                    </p>
                    <p className='text-center text-2xl font-bold items-center'>
                      You have accomplished a{' '}
                      <span className='text-blue-600'>
                        {callerTotalConnectionRate}%
                      </span>{' '}
                      connection rate after only{' '}
                      <span className='text-blue-600'>20</span> dials!
                    </p>
                    <p className='text-center text-2xl font-bold items-center'>
                      AccelerateIQ crushes the industry standard of 1% to help
                      you crush your quota!
                    </p>
                    <p className='text-center text-2xl font-bold items-center'>
                      It maybe the end of your trial, but there's lots more to
                      do!
                    </p>
                    <div className='flex justify-center items-center'>
                      <button
                        onClick={() => {
                          handleClose20thModal();
                          window.open(
                            'https://subscription.prospectiq.ai',
                            '_blank'
                          );
                        }}
                        className='inline-flex h-12 w-35 items-center justify-center gap-x-2 rounded-full border border-secondary bg-secondary px-6 py-2 text-center font-bold text-xl text-white transition hover:border-[#f4b610] hover:bg-[#f4b610] hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50 md:px-10 md:text-lg mx-5 mt-3'
                      >
                        Click here to keep going!
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </BasicModal>
          {entryMode === 'Pitch' && scriptSelected && (
            <BasicModal
              open={openOptimizeScriptModal}
              handleClose={handleCloseOptimizeScriptModal}
              handleOpen={handleOpenOptimizeScriptModal}
            >
              <div
                id='optimize-script-modal'
                className={`hs-overlay pointer-events-none z-[60] h-full w-full overflow-y-auto overflow-x-hidden focus-visible:outline-none absolute left-[35%] top-[50%] -translate-x-[50%]`}
              >
                <div className='m-3 mt-0  transition-all ease-out hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 sm:mx-auto w-fit border-2 border-blue-900 border-solid rounded-xl border'>
                  <div className='pointer-events-auto flex flex-col rounded-xl border bg-white shadow-sm'>
                    <div className='px-5'>
                      <div className='flex justfy-center p-3'>
                        <span className='font-bold text-lg'>
                          Optimize your script with AI?
                        </span>
                      </div>
                      <div className='flex items-center justify-center'>
                        <div
                          className='cursor-pointer flex min-w-24 items-center justify-center gap-x-1 rounded-full border border-accent-2 bg-transparent px-3 py-1 text-center text-sm 2xl:text-base font-medium leading-none text-accent-2 hover:border-accent-2 hover:bg-accent-2 hover:text-white transition-colors md:min-w-32 2xl:text-base mr-2 mb-5'
                          onClick={() => {
                            handleCloseOptimizeScriptModal();
                            setTabIndex(2);
                          }}
                        >
                          <span>Let's do it!</span>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='currentColor'
                            viewBox='0 0 24 24'
                            stroke-width='1.5'
                            stroke='currentColor'
                            class='w-6 h-6 text-yellow-400'
                          >
                            <path
                              stroke-linecap='round'
                              stroke-linejoin='round'
                              d='m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z'
                            />
                          </svg>
                        </div>
                        <span
                          className='cursor-pointer flex min-w-24 items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-transparent px-2 py-1 text-center text-sm 2xl:text-base font-medium leading-none text-accent-2 hover:border-accent-2 hover:bg-accent-2 hover:text-white transition-colors md:min-w-32 md:px-4 md:py-2 2xl:text-base mr-2 mb-5'
                          onClick={() => handleCloseOptimizeScriptModal()}
                        >
                          Maybe later
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </BasicModal>
          )}
          <BasicModal
            open={openScriptEditModal}
            handleClose={handleCloseScriptEditModal}
            handleOpen={handleOpenScriptEditModal}
          >
            <div
              id='edit-script-modal'
              className='hs-overlay pointer-events-none z-[60]  h-full w-full overflow-y-auto overflow-x-hidden focus-visible:outline-none absolute left-[35%] top-[30%] -translate-x-[50%]'
            >
              <div className='m-3 mt-0  transition-all ease-out hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 sm:mx-auto sm:w-full sm:max-w-xl border-2 border-blue-900 border-solid rounded-xl border'>
                <div className='pointer-events-auto flex flex-col rounded-xl border bg-white shadow-sm'>
                  <div class='flex items-center justify-between border-b-[2px] border-b-secondary/20 px-4 py-3'>
                    <h5 class='text-xl font-semibold'>
                      {scriptSelected ? 'Edit Script' : 'Add Script'}
                    </h5>
                    <button
                      onClick={handleCloseScriptEditModal}
                      type='button'
                      class='inline-flex h-8 w-8 items-center justify-center rounded-full border border-transparent text-sm font-semibold text-gray-800 transition hover:bg-gray-100 disabled:pointer-events-none disabled:opacity-50 dark:text-white dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600'
                      data-hs-overlay='#call-notes-modal'
                    >
                      <span class='sr-only'>Close</span>
                      <svg
                        class='h-4 w-4 flex-shrink-0'
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      >
                        <path d='M18 6 6 18' />
                        <path d='m6 6 12 12' />
                      </svg>
                    </button>
                  </div>
                  <div className='relative px-5 pb-5'>
                    <input
                      type='text'
                      id='scriptNameInput'
                      maxLength={20}
                      className='peer py-3 block w-full border-b-2 border-x-transparent border-b-secondary/20 border-t-transparent bg-transparent px-0 py-4 text-lg font-medium transition placeholder:text-transparent autofill:pb-2 autofill:pt-6 focus:border-x-transparent focus:border-b-secondary focus:border-t-transparent focus:pb-2 focus:pt-6 focus:outline-none focus:ring-0 disabled:pointer-events-none disabled:opacity-50 [&:not(:placeholder-shown)]:pb-2 [&:not(:placeholder-shown)]:pt-6'
                      placeholder='Script Name'
                      value={scriptEdit.ScriptName}
                      readOnly={scriptSelected}
                      autoFocus={!scriptSelected}
                      onChange={(e) =>
                        setScriptEdit({
                          ...scriptEdit,
                          ScriptName: e.target.value,
                        })
                      }
                    />
                    <label
                      htmlFor='scriptNameInput'
                      className='px-5 py-3 pointer-events-none absolute start-0 top-0 flex h-full origin-top-left items-center truncate border border-transparent px-0 font-medium transition duration-100 ease-in-out peer-focus:-translate-y-5 peer-focus:scale-90 peer-focus:text-gray-500 peer-disabled:pointer-events-none peer-disabled:opacity-50 peer-[:not(:placeholder-shown)]:-translate-y-5 peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:text-gray-500'
                    >
                      Script Name
                    </label>
                  </div>
                  <div className='overflow-y-auto text-lg px-5 pb-5'>
                    <textarea
                      value={scriptEdit.ScriptDescription}
                      onChange={(e) =>
                        setScriptEdit({
                          ...scriptEdit,
                          ScriptDescription: e.target.value,
                        })
                      }
                      rows={5}
                      autoFocus={scriptSelected}
                      name='scriptDescription'
                      className='block w-full rounded-lg border-2 border-secondary/20 px-4 py-3 text-lg font-medium transition focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500/20'
                    ></textarea>
                  </div>
                  <div className='flex items-center justify-between p-4'>
                    <div className='flex'>
                      <button
                        type='button'
                        className='inline-flex items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-transparent px-6 py-2 text-lg font-medium text-accent-2 transition hover:border-accent-2 hover:bg-accent-2 hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50'
                        onClick={handleCloseScriptEditModal}
                      >
                        Cancel
                      </button>
                      <button
                        type='button'
                        className={`${
                          scriptEdit.CallerScriptID ? 'inline-flex' : 'hidden'
                        } items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-transparent px-6 py-2 text-lg font-medium text-accent-2 transition hover:border-accent-2 hover:bg-accent-2 hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50 ml-2`}
                        onClick={handleSubmitScriptDelete}
                      >
                        Delete
                      </button>
                    </div>

                    <button
                      type='button'
                      className='inline-flex items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-accent-2 px-6 py-2 text-center font-medium text-white transition hover:border-primary hover:bg-primary hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50 md:px-10 md:text-lg'
                      onClick={handleSubmitScriptEdit}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </BasicModal>
        </div>
        {/*<!-- Appointment information  -->*/}
        <div
          className={`${
            index === 5 && happenedIndex === 0
              ? 'rounded-2xl bg-secondary/10 p-3 2xl:p-10'
              : 'hidden'
          }`}
        >
          <div className='flex flex-wrap items-center justify-between gap-2 border-b-[3px] border-secondary/20 pb-2'>
            <div className='relative flex items-center gap-2 before:absolute before:-bottom-[13px] before:left-0 before:h-[3px] before:w-[calc(100%+.5rem)] before:bg-secondary'>
              <span className='rounded-lg bg-accent-1 p-1 text-primary'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 18 18'
                  fill='currentColor'
                  className='h-5 w-5'
                >
                  <path d='M14.69 14.05a6.91 6.91 0 0 0-3.12-2.48A4.23 4.23 0 0 0 13 9.47a4.15 4.15 0 1 0-8 0 4.23 4.23 0 0 0 1.42 2.1 6.91 6.91 0 0 0-3.12 2.48 7.62 7.62 0 1 1 11.38 0ZM6.23 8.31a2.78 2.78 0 0 1 1.71-2.56 2.74 2.74 0 0 1 1.6-.16 2.78 2.78 0 0 1 2.18 2.18A2.78 2.78 0 0 1 9 11.08a2.77 2.77 0 0 1-2.77-2.77ZM4.33 15a5.55 5.55 0 0 1 9.34 0 7.58 7.58 0 0 1-9.34 0ZM9 0a9 9 0 1 0 6.36 2.64A9 9 0 0 0 9 0Z' />
                </svg>
              </span>
              <h6 className='text-lg font-medium'>Appointment Information</h6>
            </div>

            <div className='flex'>
              {index !== 5 ? (
                ''
              ) : (
                <CSVLink
                  data={csvData}
                  filename={'ContactInfo.csv'}
                  className='flex h-8 w-8 items-center justify-center rounded-lg border border-transparent bg-transparent text-accent-2 transition hover:bg-accent-2/10 focus:outline-none focus:ring focus:ring-accent-2/20 disabled:pointer-events-none disabled:opacity-50'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 24 24'
                    fill='currentColor'
                    className='w-6 h-6'
                  >
                    <path
                      fill-rule='evenodd'
                      d='M12 2.25a.75.75 0 0 1 .75.75v11.69l3.22-3.22a.75.75 0 1 1 1.06 1.06l-4.5 4.5a.75.75 0 0 1-1.06 0l-4.5-4.5a.75.75 0 1 1 1.06-1.06l3.22 3.22V3a.75.75 0 0 1 .75-.75Zm-9 13.5a.75.75 0 0 1 .75.75v2.25a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5V16.5a.75.75 0 0 1 1.5 0v2.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V16.5a.75.75 0 0 1 .75-.75Z'
                      clip-rule='evenodd'
                    />
                  </svg>
                </CSVLink>
              )}
              <button
                onClick={handleOpenEditContactModal}
                data-hs-overlay='#edit-contact-modal'
                type='button'
                className='flex h-8 w-8 items-center justify-center rounded-lg border border-transparent bg-transparent text-accent-2 transition hover:bg-accent-2/10 focus:outline-none focus:ring focus:ring-accent-2/20 disabled:pointer-events-none disabled:opacity-50'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 18 18'
                  fill='currentColor'
                  className='h-4 w-4'
                >
                  <path d='m14.4 7.62-4-4 2.16-2.16 4 4Zm-8.94 8.94h-4v-4l7.9-7.94 4 4ZM17.58 4.44l-4-4a1.6 1.6 0 0 0-.47-.31 1.5 1.5 0 0 0-.57-.13 1.46 1.46 0 0 0-.54.11 1.38 1.38 0 0 0-.47.31L.42 11.52a1.38 1.38 0 0 0-.31.48 1.46 1.46 0 0 0-.11.55v4A1.43 1.43 0 0 0 1.44 18h4a1.46 1.46 0 0 0 .56-.11 1.38 1.38 0 0 0 .47-.31l11.1-11.1a1.38 1.38 0 0 0 .32-.48 1.46 1.46 0 0 0 .11-.54 1.5 1.5 0 0 0-.11-.55 1.6 1.6 0 0 0-.31-.47Z' />
                </svg>
              </button>
            </div>
          </div>
          {/* <!-- Appointment information --> */}
          <div className='mt-0 h-justify-around'>
            <form action='' className='px-4'>
              <div className='flex items-center justify-end'>
                <a
                  href={`mailto:${formObjContact.email}`}
                  name={'email'}
                  className='flex h-8 w-8 items-center justify-center rounded-lg border border-transparent bg-transparent text-accent-2 transition hover:bg-accent-2/10 focus:outline-none focus:ring focus:ring-accent-2/20 disabled:pointer-events-none disabled:opacity-50'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2'
                    className='h-5 w-5'
                  >
                    <path d='M21.2 8.4c.5.38.8.97.8 1.6v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V10a2 2 0 0 1 .8-1.6l8-6a2 2 0 0 1 2.4 0l8 6Z' />
                    <path d='m22 10-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 10' />
                  </svg>
                </a>
                <a
                  href={`${formObjContact.linkedin}`}
                  target='_blank'
                  name={'linkedin'}
                  className='flex h-8 w-8 items-center justify-center rounded-lg border border-transparent bg-transparent text-accent-2 transition hover:bg-accent-2/10 focus:outline-none focus:ring focus:ring-accent-2/20 disabled:pointer-events-none disabled:opacity-50'
                  rel='noreferrer'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 32 32'
                    fill='currentColor'
                    className='h-6 w-6'
                  >
                    <path d='M7.5 5A2.518 2.518 0 0 0 5 7.5v17C5 25.867 6.133 27 7.5 27h17c1.367 0 2.5-1.133 2.5-2.5v-17C27 6.133 25.867 5 24.5 5Zm0 2h17c.285 0 .5.215.5.5v17a.49.49 0 0 1-.5.5h-17a.489.489 0 0 1-.5-.5v-17c0-.285.215-.5.5-.5Zm2.938 1.719a1.719 1.719 0 1 0 0 3.437 1.719 1.719 0 0 0 0-3.437Zm9.03 4.562c-1.433 0-2.386.785-2.78 1.531h-.063V13.5h-2.813V23h2.938v-4.688c0-1.238.246-2.437 1.781-2.437 1.512 0 1.532 1.398 1.532 2.5V23H23v-5.219c0-2.554-.543-4.5-3.531-4.5ZM9 13.5V23h2.969v-9.5Z' />
                  </svg>
                </a>
                <a
                  href={`${formObjContact.website}`}
                  target='_blank'
                  name={'website'}
                  className='flex h-8 w-8 items-center justify-center rounded-lg border border-transparent bg-transparent text-accent-2 transition hover:bg-accent-2/10 focus:outline-none focus:ring focus:ring-accent-2/20 disabled:pointer-events-none disabled:opacity-50'
                  rel='noreferrer'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2'
                    className='h-5 w-5'
                  >
                    <circle cx='12' cy='12' r='10' />
                    <path d='M12 2a14.5 14.5 0 0 0 0 20 14.5 14.5 0 0 0 0-20M2 12h20' />
                  </svg>
                </a>
              </div>
              <div className='space-y-4'>
                <div className='relative'>
                  <input
                    type='text'
                    id='contact-name'
                    name='contact'
                    className='peer block w-full border-b-2 border-x-transparent border-b-secondary/20 border-t-transparent bg-transparent px-0 py-4 text-sm 2xl:text-base font-medium transition placeholder:text-transparent autofill:pb-2 autofill:pt-6 focus:border-x-transparent focus:border-b-secondary focus:border-t-transparent focus:pb-2 focus:pt-6 focus:outline-none focus:ring-0 disabled:pointer-events-none disabled:opacity-50 [&:not(:placeholder-shown)]:pb-2 [&:not(:placeholder-shown)]:pt-6'
                    placeholder='Contact Name'
                    value={formObjContact.contact}
                    readOnly
                  />
                  <label
                    htmlFor='contact-name'
                    className='pointer-events-none absolute start-0 top-0 flex h-full origin-top-left items-center truncate border border-transparent px-0 font-medium transition duration-100 ease-in-out peer-focus:-translate-y-3 peer-focus:scale-90 peer-focus:text-gray-500 peer-disabled:pointer-events-none peer-disabled:opacity-50 peer-[:not(:placeholder-shown)]:-translate-y-3 peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:text-gray-500'
                  >
                    Contact Name
                  </label>
                </div>
                <div className='relative'>
                  <input
                    type='text'
                    id='title'
                    name='title'
                    className='peer block w-full border-b-2 border-x-transparent border-b-secondary/20 border-t-transparent bg-transparent px-0 py-4 text-sm 2xl:text-base font-medium transition placeholder:text-transparent autofill:pb-2 autofill:pt-6 focus:border-x-transparent focus:border-b-secondary focus:border-t-transparent focus:pb-2 focus:pt-6 focus:outline-none focus:ring-0 disabled:pointer-events-none disabled:opacity-50 [&:not(:placeholder-shown)]:pb-2 [&:not(:placeholder-shown)]:pt-6'
                    placeholder='Title'
                    value={formObjContact.title}
                    readOnly
                  />
                  <label
                    htmlFor='title'
                    className='pointer-events-none absolute start-0 top-0 flex h-full origin-top-left items-center truncate border border-transparent px-0 font-medium transition duration-100 ease-in-out peer-focus:-translate-y-3 peer-focus:scale-90 peer-focus:text-gray-500 peer-disabled:pointer-events-none peer-disabled:opacity-50 peer-[:not(:placeholder-shown)]:-translate-y-3 peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:text-gray-500'
                  >
                    Title
                  </label>
                </div>
                <div className='grid grid-cols-1 gap-0 h-gap-3 xl:grid-cols-1'>
                  <div className='relative'>
                    <input
                      type='text'
                      id='company-name'
                      name='company'
                      className='peer block w-full border-b-2 border-x-transparent border-b-secondary/20 border-t-transparent bg-transparent px-0 py-4 text-sm 2xl:text-base font-medium transition placeholder:text-transparent autofill:pb-2 autofill:pt-6 focus:border-x-transparent focus:border-b-secondary focus:border-t-transparent focus:pb-2 focus:pt-6 focus:outline-none focus:ring-0 disabled:pointer-events-none disabled:opacity-50 [&:not(:placeholder-shown)]:pb-2 [&:not(:placeholder-shown)]:pt-6'
                      placeholder='Company Name'
                      value={formObjContact.company}
                      readOnly
                    />
                    <label
                      htmlFor='company-name'
                      className='pointer-events-none absolute start-0 top-0 flex h-full origin-top-left items-center truncate border border-transparent px-0 font-medium transition duration-100 ease-in-out peer-focus:-translate-y-3 peer-focus:scale-90 peer-focus:text-gray-500 peer-disabled:pointer-events-none peer-disabled:opacity-50 peer-[:not(:placeholder-shown)]:-translate-y-3 peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:text-gray-500'
                    >
                      Company Name
                    </label>
                  </div>
                  <div className='relative'>
                    <input
                      type='text'
                      id='industry'
                      name='industry'
                      className='peer block w-full border-b-2 border-x-transparent border-b-secondary/20 border-t-transparent bg-transparent px-0 py-4 text-sm 2xl:text-base font-medium transition placeholder:text-transparent autofill:pb-2 autofill:pt-6 focus:border-x-transparent focus:border-b-secondary focus:border-t-transparent focus:pb-2 focus:pt-6 focus:outline-none focus:ring-0 disabled:pointer-events-none disabled:opacity-50 [&:not(:placeholder-shown)]:pb-2 [&:not(:placeholder-shown)]:pt-6'
                      placeholder='Industry'
                      value={formObjContact.industry}
                      readOnly
                    />
                    <label
                      htmlFor='industry'
                      className='pointer-events-none absolute start-0 top-0 flex h-full origin-top-left items-center truncate border border-transparent px-0 font-medium transition duration-100 ease-in-out peer-focus:-translate-y-3 peer-focus:scale-90 peer-focus:text-gray-500 peer-disabled:pointer-events-none peer-disabled:opacity-50 peer-[:not(:placeholder-shown)]:-translate-y-3 peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:text-gray-500'
                    >
                      Industry
                    </label>
                  </div>
                </div>
                <div className='grid grid-cols-2 gap-4 md:grid-cols-2 xl:grid-cols-2'>
                  <div className='relative'>
                    <input
                      type='text'
                      id='bestphoneinput'
                      name='time'
                      className='peer block w-full border-b-2 border-x-transparent border-b-secondary/20 border-t-transparent bg-transparent px-0 py-4 text-sm 2xl:text-base font-medium transition placeholder:text-transparent autofill:pb-2 autofill:pt-6 focus:border-x-transparent focus:border-b-secondary focus:border-t-transparent focus:pb-2 focus:pt-6 focus:outline-none focus:ring-0 disabled:pointer-events-none disabled:opacity-50 [&:not(:placeholder-shown)]:pb-2 [&:not(:placeholder-shown)]:pt-6'
                      placeholder='Best Phone'
                      value={formatPhoneNumber(formObjContact.bestPhone)}
                      readOnly
                    />
                    <label
                      htmlFor='bestphoneinput'
                      className='pointer-events-none absolute start-0 top-0 flex h-full origin-top-left items-center truncate border border-transparent px-0 font-medium transition duration-100 ease-in-out peer-focus:-translate-y-3 peer-focus:scale-90 peer-focus:text-gray-500 peer-disabled:pointer-events-none peer-disabled:opacity-50 peer-[:not(:placeholder-shown)]:-translate-y-3 peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:text-gray-500'
                    >
                      Best Phone
                    </label>
                  </div>
                </div>
                <div className='grid grid-cols-3 gap-4 md:grid-cols-3 xl:grid-cols-3'>
                  <div className='relative'>
                    <input
                      type='text'
                      id='apptDateinput'
                      name='time'
                      className='peer block w-full border-b-2 border-x-transparent border-b-secondary/20 border-t-transparent bg-transparent px-0 py-4 text-sm 2xl:text-base font-medium transition placeholder:text-transparent autofill:pb-2 autofill:pt-6 focus:border-x-transparent focus:border-b-secondary focus:border-t-transparent focus:pb-2 focus:pt-6 focus:outline-none focus:ring-0 disabled:pointer-events-none disabled:opacity-50 [&:not(:placeholder-shown)]:pb-2 [&:not(:placeholder-shown)]:pt-6'
                      placeholder='Appt Date'
                      value={moment(pickerInfo).format('MM/DD/YYYY')}
                      readOnly
                    />
                    <label
                      htmlFor='apptDateinput'
                      className='pointer-events-none absolute start-0 top-0 flex h-full origin-top-left items-center truncate border border-transparent px-0 font-medium transition duration-100 ease-in-out peer-focus:-translate-y-3 peer-focus:scale-90 peer-focus:text-gray-500 peer-disabled:pointer-events-none peer-disabled:opacity-50 peer-[:not(:placeholder-shown)]:-translate-y-3 peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:text-gray-500'
                    >
                      Appt Date
                    </label>
                  </div>
                  <div className='relative'>
                    <input
                      type='text'
                      id='apptTimeInput'
                      name='time'
                      className='peer block w-full border-b-2 border-x-transparent border-b-secondary/20 border-t-transparent bg-transparent px-0 py-4 text-sm 2xl:text-base font-medium transition placeholder:text-transparent autofill:pb-2 autofill:pt-6 focus:border-x-transparent focus:border-b-secondary focus:border-t-transparent focus:pb-2 focus:pt-6 focus:outline-none focus:ring-0 disabled:pointer-events-none disabled:opacity-50 [&:not(:placeholder-shown)]:pb-2 [&:not(:placeholder-shown)]:pt-6'
                      placeholder='Appt Time'
                      value={`${timeInfo} ${getUserTimeZone()}`}
                      readOnly
                    />
                    <label
                      htmlFor='apptTimeInput'
                      className='pointer-events-none absolute start-0 top-0 flex h-full origin-top-left items-center truncate border border-transparent px-0 font-medium transition duration-100 ease-in-out peer-focus:-translate-y-3 peer-focus:scale-90 peer-focus:text-gray-500 peer-disabled:pointer-events-none peer-disabled:opacity-50 peer-[:not(:placeholder-shown)]:-translate-y-3 peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:text-gray-500'
                    >
                      Appt Time
                    </label>
                  </div>
                  <div className='relative'>
                    <input
                      type='text'
                      id='apptTypeInput'
                      name='time'
                      className='peer block w-full border-b-2 border-x-transparent border-b-secondary/20 border-t-transparent bg-transparent px-0 py-4 text-sm 2xl:text-base font-medium transition placeholder:text-transparent autofill:pb-2 autofill:pt-6 focus:border-x-transparent focus:border-b-secondary focus:border-t-transparent focus:pb-2 focus:pt-6 focus:outline-none focus:ring-0 disabled:pointer-events-none disabled:opacity-50 [&:not(:placeholder-shown)]:pb-2 [&:not(:placeholder-shown)]:pt-6'
                      placeholder='Appointment Type'
                      value={appointment}
                      readOnly
                    />
                    <label
                      htmlFor='apptTypeInput'
                      className='pointer-events-none absolute start-0 top-0 flex h-full origin-top-left items-center truncate border border-transparent px-0 font-medium transition duration-100 ease-in-out peer-focus:-translate-y-3 peer-focus:scale-90 peer-focus:text-gray-500 peer-disabled:pointer-events-none peer-disabled:opacity-50 peer-[:not(:placeholder-shown)]:-translate-y-3 peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:text-gray-500'
                    >
                      Appointment Type
                    </label>
                  </div>
                </div>
              </div>
            </form>
            <div class='mt-5 2xl:mb-3 flex flex-wrap items-center justify-between gap-2'>
              <a
                onClick={() => {
                  setIndex(19);
                }}
                class='cursor-pointer inline-flex items-center gap-x-2 text-sm text-gray-800 transition hover:text-secondary'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  stroke='currentColor'
                  stroke-width='1.5'
                  class='h-4 w-4'
                  viewBox='0 0 24 24'
                >
                  <path
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    d='M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18'
                  />
                </svg>
                Go Back
              </a>
              <button
                onClick={() => {
                  updateDialOutcomes(0);
                  setFinishClicked(true);
                  handleFinish();
                }}
                class='inline-flex items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-accent-2 px-6 py-2 text-center font-medium text-white transition hover:border-primary hover:bg-primary hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50 md:px-10 md:text-lg'
                disabled={finishClicked}
              >
                Finish
              </button>
              <span
                onClick={handleOpenCallNotesModal}
                data-hs-overlay='#call-notes-modal'
                className={`cursor-pointer rounded-lg p-1 text-primary ${
                  formCallNotes && formCallNotes !== 'null'
                    ? 'bg-secondary'
                    : 'bg-transparent-1'
                }`}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  fill='currentColor'
                  class='h-6 w-6'
                >
                  <path d='M17 17v3.59L20.59 17ZM2 22h13v-6a1 1 0 0 1 1-1h6V2H2ZM24 2v13.59a2 2 0 0 1-.15.76 1.92 1.92 0 0 1-.44.65L17 23.41a2 2 0 0 1-.65.44 2 2 0 0 1-.76.15H2a2 2 0 0 1-1.41-.59A2 2 0 0 1 0 22V2A2 2 0 0 1 .59.59 2 2 0 0 1 2 0h20a2 2 0 0 1 1.41.59A2 2 0 0 1 24 2ZM12 15H8a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2Zm-4-2h8a1 1 0 1 0 0-2H8a1 1 0 1 0 0 2ZM7 8a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2H8a1 1 0 0 1-1-1Z' />
                </svg>
              </span>
            </div>
          </div>
        </div>
        {/*<!-- Contact details  -->*/}
        <div
          class={`${
            (index === 5 && happenedIndex === 0) || index === 0
              ? 'hidden'
              : 'flex flex-col rounded-2xl bg-secondary/10 p-3 2xl:p-10'
          }`}
        >
          <div class='flex flex-wrap items-center justify-between gap-2 border-b-[3px] border-secondary/20 pb-2'>
            <div class='relative flex items-center gap-2 before:absolute before:-bottom-[11px] before:left-0 before:h-[3px] before:w-[calc(100%+.5rem)] before:bg-secondary'>
              <CallIcon className='w-5 h-5' />
              <h6 class='text-lg font-medium'>Call Center</h6>
            </div>
          </div>
          <div class='mt-10 mx-4 mb-6 flex h-full flex-col justify-between gap-4'>
            {index === 1 && (
              <WhatHappened
                setIndex={setIndex}
                setHappenedIndex={setHappenedIndex}
                happenedIndex={happenedIndex}
                updateDialoutcomes={updateDialOutcomes}
                handleOpen3={handleOpenCallNotesModal}
                handleFinish={handleFinish}
              />
            )}
            {index === 2 && (
              <Schedule1
                setPickerInfo={setPickerInfo}
                setIndex={setIndex}
                pickerInfo={pickerInfo}
                formObContact={formObjContact}
                setFormObjContact={setFormObjContact}
                handleOpen3={handleOpenCallNotesModal}
              />
            )}
            {index === 3 && (
              <Schedule2
                happenedIndex={happenedIndex}
                setIndex={setIndex}
                setTimeInfo={setTimeInfo}
                timeInfo={timeInfo}
                setFormObjContact={setFormObjContact}
                formObjContact={formObjContact}
                handleOpen3={handleOpenCallNotesModal}
              />
            )}
            {index === 4 && (
              <Schedule3
                appointment={appointment}
                happenedIndex={happenedIndex}
                setIndex={setIndex}
                toggleIsPlayer={toggleIsPlayer}
                pickerInfo={pickerInfo}
                timeInfo={timeInfo}
                handleFinish={handleFinish}
                updateDialoutcomes={updateDialOutcomes}
                handleOpen3={handleOpenCallNotesModal}
              />
            )}
            {happenedIndex === 1 && index === 5 && (
              <Schedule4
                happenedIndex={happenedIndex}
                setIndex={setIndex}
                toggleIsPlayer={toggleIsPlayer}
                updateDialoutcomes={updateDialOutcomes}
                handleFinish={handleFinish}
              />
            )}
            {index === 6 && (
              <Email1 setIndex={setIndex} setEmailType={setEmailType} />
            )}
            {index === 7 && (
              <Email2
                setIndex={setIndex}
                formObjContact={formObjContact}
                emailType={emailType}
                handleFinish={handleFinish}
                updateDialoutcomes={updateDialOutcomes}
                happenedIndex={happenedIndex}
                handleOpen3={handleOpenCallNotesModal}
              />
            )}
            {index === 8 && (
              <Email3
                setIndex={setIndex}
                formObjContact={formObjContact}
                updateDialoutcomes={updateDialOutcomes}
                happenedIndex={happenedIndex}
                handleFinish={handleFinish}
              />
            )}
            {index === 9 && (
              <Referral
                formObj={formObj}
                setFormObj={setFormObj}
                setIndex={setIndex}
              />
            )}
            {index === 10 && (
              <Referral2
                formObj={formObj}
                setFormObj={setFormObj}
                setIndex={setIndex}
              />
            )}
            {index === 11 && (
              <Referral3
                formObj={formObj}
                setFormObj={setFormObj}
                setIndex={setIndex}
              />
            )}
            {index === 12 && (
              <Referral4
                formObj={formObj}
                setFormObj={setFormObj}
                setIndex={setIndex}
              />
            )}
            {index === 13 && (
              <Referral5
                formObj={formObj}
                setFormObj={setFormObj}
                setIndex={setIndex}
              />
            )}
            {index === 14 && (
              <Referral6
                formObj={formObj}
                setFormObj={setFormObj}
                setIndex={setIndex}
                happenedIndex={happenedIndex}
                updateDialoutcomes={updateDialOutcomes}
              />
            )}
            {index === 15 && (
              <Hungup
                setIndex={setIndex}
                happenedIndex={happenedIndex}
                updateDialoutcomes={updateDialOutcomes}
                handleFinish={handleFinish}
              />
            )}
            {index === 16 && (
              <NotInterested
                setIndex={setIndex}
                handleFinish={handleFinish}
                happenedIndex={happenedIndex}
                updateDialoutcomes={updateDialOutcomes}
                handleOpen3={handleOpenCallNotesModal}
              />
            )}
            {index === 17 && <Discard setIndex={setIndex} />}
            {index === 18 && (
              <CommonDiscard
                setIndex={setIndex}
                handleFinish={handleFinish}
                happenedIndex={happenedIndex}
                updateDialoutcomes={updateDialOutcomes}
                setDialOutcomeDetail={setDialOutcomeDetail}
                handleOpen3={handleOpenCallNotesModal}
              />
            )}
            {index === 19 && (
              <Appointment
                setIndex={setIndex}
                setAppointment={setAppointment}
                handleOpen3={handleOpenCallNotesModal}
              />
            )}
          </div>
        </div>
      </div>
    </ManagerDashboard>
  );
};

export default ConversionLab;
