import React from 'react';
import BasicModal from '../../Common/BasicModel';

const EditContactModal = ({
  isOpen,
  onClose,
  formObjContact,
  setFormObjContact,
}) => {
  if (!isOpen) return null;

  const handleSubmitContact = () => {
    onClose();
    fetch('/call-contact-update', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formObjContact),
    })
      .then((res) => {
        if (!res.ok && res.status === 401) {
          window.location.href = '/login';
        }
      })
      .catch((err) => console.error(err));
  };

  const handleChangeContact = (e) => {
    const { name, value } = e.target;
    setFormObjContact((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <BasicModal open={isOpen} handleClose={onClose}>
      <div
        id='edit-contact-modal'
        className='hs-overlay pointer-events-none fixed start-0 top-8 z-[60]  h-full w-full overflow-y-auto overflow-x-hidden'
      >
        <div className='m-3 mt-0 transition-all ease-out hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 sm:mx-auto sm:w-full sm:max-w-xl'>
          <div className='pointer-events-auto flex flex-col rounded-xl border bg-white shadow-sm'>
            <div className='flex items-center justify-between border-b-[2px] border-b-secondary/20 px-4 py-3'>
              <h5 className='text-xl font-semibold'>Contact Information</h5>
              <button
                type='button'
                className='inline-flex h-8 w-8 items-center justify-center rounded-full border border-transparent text-sm font-semibold text-gray-800 transition hover:bg-gray-100 disabled:pointer-events-none disabled:opacity-50 dark:text-white dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600'
                data-hs-overlay='#edit-contact-modal'
                onClick={onClose}
              >
                <span className='sr-only'>Close</span>
                <svg
                  className='h-4 w-4 flex-shrink-0'
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                >
                  <path d='M18 6 6 18' />
                  <path d='m6 6 12 12' />
                </svg>
              </button>
            </div>
            <div className='overflow-y-auto p-5'>
              <form action='' className=''>
                <div className='space-y-4'>
                  <div className='grid grid-cols-1 gap-4 xl:grid-cols-2'>
                    <div className='relative'>
                      <input
                        type={'hidden'}
                        name={'contactId'}
                        value={formObjContact.contactId}
                      />
                      <input
                        type='text'
                        id='company-name'
                        className='peer block w-full border-b-2 border-x-transparent border-b-secondary/20 border-t-transparent bg-transparent px-0 py-4 text-xl font-medium transition placeholder:text-transparent autofill:pb-2 autofill:pt-6 focus:border-x-transparent focus:border-b-secondary focus:border-t-transparent focus:pb-2 focus:pt-6 focus:outline-none focus:ring-0 disabled:pointer-events-none disabled:opacity-50 [&:not(:placeholder-shown)]:pb-2 [&:not(:placeholder-shown)]:pt-6'
                        placeholder='Company Name'
                        name='company'
                        value={formObjContact.company}
                        autoFocus={true}
                        onChange={handleChangeContact}
                      />
                      <label
                        htmlFor='company-name'
                        className='pointer-events-none absolute start-0 top-0 flex h-full origin-top-left items-center truncate border border-transparent px-0 font-medium transition duration-100 ease-in-out peer-focus:-translate-y-3 peer-focus:scale-90 peer-focus:text-gray-500 peer-disabled:pointer-events-none peer-disabled:opacity-50 peer-[:not(:placeholder-shown)]:-translate-y-3 peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:text-gray-500'
                      >
                        Company Name
                      </label>
                    </div>
                    <div className='relative'>
                      <input
                        type='text'
                        id='contact-name'
                        className='peer block w-full border-b-2 border-x-transparent border-b-secondary/20 border-t-transparent bg-transparent px-0 py-4 text-xl font-medium transition placeholder:text-transparent autofill:pb-2 autofill:pt-6 focus:border-x-transparent focus:border-b-secondary focus:border-t-transparent focus:pb-2 focus:pt-6 focus:outline-none focus:ring-0 disabled:pointer-events-none disabled:opacity-50 [&:not(:placeholder-shown)]:pb-2 [&:not(:placeholder-shown)]:pt-6'
                        placeholder='Contact Name'
                        value={formObjContact.contact}
                        name='contact'
                        onChange={handleChangeContact}
                      />
                      <label
                        htmlFor='contact-name'
                        className='pointer-events-none absolute start-0 top-0 flex h-full origin-top-left items-center truncate border border-transparent px-0 font-medium transition duration-100 ease-in-out peer-focus:-translate-y-3 peer-focus:scale-90 peer-focus:text-gray-500 peer-disabled:pointer-events-none peer-disabled:opacity-50 peer-[:not(:placeholder-shown)]:-translate-y-3 peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:text-gray-500'
                      >
                        Contact Name
                      </label>
                    </div>
                  </div>
                  <div className='relative'>
                    <input
                      type='text'
                      id='title'
                      className='peer block w-full border-b-2 border-x-transparent border-b-secondary/20 border-t-transparent bg-transparent px-0 py-4 text-xl font-medium transition placeholder:text-transparent autofill:pb-2 autofill:pt-6 focus:border-x-transparent focus:border-b-secondary focus:border-t-transparent focus:pb-2 focus:pt-6 focus:outline-none focus:ring-0 disabled:pointer-events-none disabled:opacity-50 [&:not(:placeholder-shown)]:pb-2 [&:not(:placeholder-shown)]:pt-6'
                      placeholder='Title'
                      value={formObjContact.title}
                      name='title'
                      onChange={handleChangeContact}
                    />
                    <label
                      htmlFor='title'
                      className='pointer-events-none absolute start-0 top-0 flex h-full origin-top-left items-center truncate border border-transparent px-0 font-medium transition duration-100 ease-in-out peer-focus:-translate-y-3 peer-focus:scale-90 peer-focus:text-gray-500 peer-disabled:pointer-events-none peer-disabled:opacity-50 peer-[:not(:placeholder-shown)]:-translate-y-3 peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:text-gray-500'
                    >
                      Title
                    </label>
                  </div>
                  <div className='grid grid-cols-1 gap-4 xl:grid-cols-2'>
                    <div className='relative'>
                      <input
                        type='text'
                        id='industry'
                        className='peer block w-full border-b-2 border-x-transparent border-b-secondary/20 border-t-transparent bg-transparent px-0 py-4 text-xl font-medium transition placeholder:text-transparent autofill:pb-2 autofill:pt-6 focus:border-x-transparent focus:border-b-secondary focus:border-t-transparent focus:pb-2 focus:pt-6 focus:outline-none focus:ring-0 disabled:pointer-events-none disabled:opacity-50 [&:not(:placeholder-shown)]:pb-2 [&:not(:placeholder-shown)]:pt-6'
                        placeholder='Industry'
                        value={formObjContact.industry}
                        name='industry'
                        onChange={handleChangeContact}
                      />
                      <label
                        htmlFor='industry'
                        className='pointer-events-none absolute start-0 top-0 flex h-full origin-top-left items-center truncate border border-transparent px-0 font-medium transition duration-100 ease-in-out peer-focus:-translate-y-3 peer-focus:scale-90 peer-focus:text-gray-500 peer-disabled:pointer-events-none peer-disabled:opacity-50 peer-[:not(:placeholder-shown)]:-translate-y-3 peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:text-gray-500'
                      >
                        Industry
                      </label>
                    </div>
                    <div className='relative'>
                      <input
                        type='text'
                        id='timezone'
                        className='peer block w-full border-b-2 border-x-transparent border-b-secondary/20 border-t-transparent bg-transparent px-0 py-4 text-xl font-medium transition placeholder:text-transparent autofill:pb-2 autofill:pt-6 focus:border-x-transparent focus:border-b-secondary focus:border-t-transparent focus:pb-2 focus:pt-6 focus:outline-none focus:ring-0 disabled:pointer-events-none disabled:opacity-50 [&:not(:placeholder-shown)]:pb-2 [&:not(:placeholder-shown)]:pt-6'
                        placeholder='Time Zone'
                        value={formObjContact.time}
                        name='time'
                        onChange={handleChangeContact}
                      />
                      <label
                        htmlFor='timezone'
                        className='pointer-events-none absolute start-0 top-0 flex h-full origin-top-left items-center truncate border border-transparent px-0 font-medium transition duration-100 ease-in-out peer-focus:-translate-y-3 peer-focus:scale-90 peer-focus:text-gray-500 peer-disabled:pointer-events-none peer-disabled:opacity-50 peer-[:not(:placeholder-shown)]:-translate-y-3 peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:text-gray-500'
                      >
                        Time Zone
                      </label>
                    </div>
                  </div>
                  <div className='grid grid-cols-1 gap-4 xl:grid-cols-3'>
                    <div className='relative'>
                      <input
                        type='text'
                        id='address'
                        className='peer block w-full border-b-2 border-x-transparent border-b-secondary/20 border-t-transparent bg-transparent px-0 py-4 text-xl font-medium transition placeholder:text-transparent autofill:pb-2 autofill:pt-6 focus:border-x-transparent focus:border-b-secondary focus:border-t-transparent focus:pb-2 focus:pt-6 focus:outline-none focus:ring-0 disabled:pointer-events-none disabled:opacity-50 [&:not(:placeholder-shown)]:pb-2 [&:not(:placeholder-shown)]:pt-6'
                        placeholder='Contact Address'
                        value={formObjContact.address}
                        name='address'
                        onChange={handleChangeContact}
                      />
                      <label
                        htmlFor='address'
                        className='pointer-events-none absolute start-0 top-0 flex h-full origin-top-left items-center truncate border border-transparent px-0 font-medium transition duration-100 ease-in-out peer-focus:-translate-y-3 peer-focus:scale-90 peer-focus:text-gray-500 peer-disabled:pointer-events-none peer-disabled:opacity-50 peer-[:not(:placeholder-shown)]:-translate-y-3 peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:text-gray-500'
                      >
                        Contact Address
                      </label>
                    </div>
                    <div className='relative'>
                      <input
                        type='text'
                        id='city'
                        className='peer block w-full border-b-2 border-x-transparent border-b-secondary/20 border-t-transparent bg-transparent px-0 py-4 text-xl font-medium transition placeholder:text-transparent autofill:pb-2 autofill:pt-6 focus:border-x-transparent focus:border-b-secondary focus:border-t-transparent focus:pb-2 focus:pt-6 focus:outline-none focus:ring-0 disabled:pointer-events-none disabled:opacity-50 [&:not(:placeholder-shown)]:pb-2 [&:not(:placeholder-shown)]:pt-6'
                        placeholder='City'
                        value={formObjContact.city}
                        name='city'
                        onChange={handleChangeContact}
                      />
                      <label
                        htmlFor='city'
                        className='pointer-events-none absolute start-0 top-0 flex h-full origin-top-left items-center truncate border border-transparent px-0 font-medium transition duration-100 ease-in-out peer-focus:-translate-y-3 peer-focus:scale-90 peer-focus:text-gray-500 peer-disabled:pointer-events-none peer-disabled:opacity-50 peer-[:not(:placeholder-shown)]:-translate-y-3 peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:text-gray-500'
                      >
                        City
                      </label>
                    </div>
                    <div className='relative'>
                      <input
                        type='text'
                        id='state'
                        className='peer block w-full border-b-2 border-x-transparent border-b-secondary/20 border-t-transparent bg-transparent px-0 py-4 text-xl font-medium transition placeholder:text-transparent autofill:pb-2 autofill:pt-6 focus:border-x-transparent focus:border-b-secondary focus:border-t-transparent focus:pb-2 focus:pt-6 focus:outline-none focus:ring-0 disabled:pointer-events-none disabled:opacity-50 [&:not(:placeholder-shown)]:pb-2 [&:not(:placeholder-shown)]:pt-6'
                        placeholder='State'
                        value={formObjContact.state}
                        name='state'
                        onChange={handleChangeContact}
                      />
                      <label
                        htmlFor='state'
                        className='pointer-events-none absolute start-0 top-0 flex h-full origin-top-left items-center truncate border border-transparent px-0 font-medium transition duration-100 ease-in-out peer-focus:-translate-y-3 peer-focus:scale-90 peer-focus:text-gray-500 peer-disabled:pointer-events-none peer-disabled:opacity-50 peer-[:not(:placeholder-shown)]:-translate-y-3 peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:text-gray-500'
                      >
                        State
                      </label>
                    </div>
                  </div>
                  <div className='grid grid-cols-1 gap-4 xl:grid-cols-1'>
                    <div className='relative'>
                      <input
                        type='text'
                        id='website'
                        className='peer block w-full border-b-2 border-x-transparent border-b-secondary/20 border-t-transparent bg-transparent px-0 py-4 text-xl font-medium transition placeholder:text-transparent autofill:pb-2 autofill:pt-6 focus:border-x-transparent focus:border-b-secondary focus:border-t-transparent focus:pb-2 focus:pt-6 focus:outline-none focus:ring-0 disabled:pointer-events-none disabled:opacity-50 [&:not(:placeholder-shown)]:pb-2 [&:not(:placeholder-shown)]:pt-6'
                        placeholder='Website'
                        value={formObjContact.website}
                        name='website'
                        onChange={handleChangeContact}
                      />
                      <label
                        htmlFor='website'
                        className='pointer-events-none absolute start-0 top-0 flex h-full origin-top-left items-center truncate border border-transparent px-0 font-medium transition duration-100 ease-in-out peer-focus:-translate-y-3 peer-focus:scale-90 peer-focus:text-gray-500 peer-disabled:pointer-events-none peer-disabled:opacity-50 peer-[:not(:placeholder-shown)]:-translate-y-3 peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:text-gray-500'
                      >
                        Website
                      </label>
                    </div>
                    <div className='relative'>
                      <input
                        type='text'
                        id='email'
                        className='peer block w-full border-b-2 border-x-transparent border-b-secondary/20 border-t-transparent bg-transparent px-0 py-4 text-xl font-medium transition placeholder:text-transparent autofill:pb-2 autofill:pt-6 focus:border-x-transparent focus:border-b-secondary focus:border-t-transparent focus:pb-2 focus:pt-6 focus:outline-none focus:ring-0 disabled:pointer-events-none disabled:opacity-50 [&:not(:placeholder-shown)]:pb-2 [&:not(:placeholder-shown)]:pt-6'
                        placeholder='Email'
                        value={formObjContact.email}
                        name='email'
                        onChange={handleChangeContact}
                      />
                      <label
                        htmlFor='email'
                        className='pointer-events-none absolute start-0 top-0 flex h-full origin-top-left items-center truncate border border-transparent px-0 font-medium transition duration-100 ease-in-out peer-focus:-translate-y-3 peer-focus:scale-90 peer-focus:text-gray-500 peer-disabled:pointer-events-none peer-disabled:opacity-50 peer-[:not(:placeholder-shown)]:-translate-y-3 peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:text-gray-500'
                      >
                        Email
                      </label>
                    </div>

                    <div className='relative'>
                      <input
                        type='text'
                        id='linkedin'
                        className='peer block w-full border-b-2 border-x-transparent border-b-secondary/20 border-t-transparent bg-transparent px-0 py-4 text-xl font-medium transition placeholder:text-transparent autofill:pb-2 autofill:pt-6 focus:border-x-transparent focus:border-b-secondary focus:border-t-transparent focus:pb-2 focus:pt-6 focus:outline-none focus:ring-0 disabled:pointer-events-none disabled:opacity-50 [&:not(:placeholder-shown)]:pb-2 [&:not(:placeholder-shown)]:pt-6'
                        placeholder='LinkedIn'
                        value={formObjContact.linkedin}
                        name='linkedin'
                        onChange={handleChangeContact}
                      />
                      <label
                        htmlFor='linkedin'
                        className='pointer-events-none absolute start-0 top-0 flex h-full origin-top-left items-center truncate border border-transparent px-0 font-medium transition duration-100 ease-in-out peer-focus:-translate-y-3 peer-focus:scale-90 peer-focus:text-gray-500 peer-disabled:pointer-events-none peer-disabled:opacity-50 peer-[:not(:placeholder-shown)]:-translate-y-3 peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:text-gray-500'
                      >
                        LinkedIn
                      </label>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div className='flex items-center justify-between p-4'>
              <button
                data-hs-overlay='#edit-contact-modal'
                type='button'
                className='inline-flex items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-transparent px-6 py-2 text-lg font-medium text-accent-2 transition hover:border-accent-2 hover:bg-accent-2 hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50'
                onClick={onClose}
              >
                Cancel
              </button>

              <button
                data-hs-overlay='#edit-contact-modal'
                type='button'
                className='inline-flex items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-accent-2 px-6 py-2 text-center font-medium text-white transition hover:border-primary hover:bg-primary hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50 md:px-10 md:text-lg'
                onClick={handleSubmitContact}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </BasicModal>
  );
};

export default EditContactModal;
