import React from 'react';
import BasicModal from '../../Common/BasicModel';

const DiscardModal = ({
  isOpen,
  onClose,
  updateDialOutcomes,
  setHappenedIndex,
  handleFinish,
}) => {
  return (
    <BasicModal open={isOpen} handleClose={onClose}>
      <div
        id='discard-modal'
        class='hs-overlay pointer-events-none fixed start-0 top-8 z-[60]  h-full w-full overflow-y-auto overflow-x-hidden focus-visible:outline-none'
      >
        <div class='m-3 mt-0  transition-all ease-out hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 sm:mx-auto sm:w-full sm:max-w-xl'>
          <div class='pointer-events-auto flex flex-col rounded-xl border bg-white shadow-sm'>
            <div class='flex items-center justify-between border-b-[2px] border-b-secondary/20 px-4 py-3'>
              <h5 class='text-xl font-semibold'>Reasons to discard</h5>
              <button
                onClick={onClose}
                type='button'
                class='inline-flex h-8 w-8 items-center justify-center rounded-full border border-transparent text-sm font-semibold text-gray-800 transition hover:bg-gray-100 disabled:pointer-events-none disabled:opacity-50 dark:text-white dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600'
                data-hs-overlay='#discard-modal'
              >
                <span class='sr-only'>Close</span>
                <svg
                  class='h-4 w-4 flex-shrink-0'
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                >
                  <path d='M18 6 6 18' />
                  <path d='m6 6 12 12' />
                </svg>
              </button>
            </div>

            <div class='overflow-y-auto p-4'>
              <div class='flex flex-col items-center justify-center gap-4'>
                <span
                  onClick={() => {
                    updateDialOutcomes(7, 'Wrong Contact');
                    setHappenedIndex(7);
                    handleFinish();
                    onClose();
                  }}
                  class='cursor-pointer inline-flex min-w-40 items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-transparent px-6 py-2 text-center font-medium text-accent-2 transition hover:border-accent-2 hover:bg-accent-2 hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50 md:text-lg'
                >
                  Wrong Contact
                </span>
                <span
                  onClick={() => {
                    updateDialOutcomes(7, 'Company not in business');
                    setHappenedIndex(7);
                    handleFinish();
                    onClose();
                  }}
                  class='cursor-pointer inline-flex min-w-40 items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-transparent px-6 py-2 text-center font-medium text-accent-2 transition hover:border-accent-2 hover:bg-accent-2 hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50 md:text-lg'
                >
                  Company not in business
                </span>
                <span
                  onClick={() => {
                    updateDialOutcomes(7, 'Do not call');
                    setHappenedIndex(7);
                    handleFinish();
                    onClose();
                  }}
                  class='cursor-pointer inline-flex min-w-40 items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-transparent px-6 py-2 text-center font-medium text-accent-2 transition hover:border-accent-2 hover:bg-accent-2 hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50 md:text-lg'
                >
                  Do not call
                </span>
                <span
                  onClick={() => {
                    updateDialOutcomes(7, 'Unsuitable company');
                    setHappenedIndex(7);
                    handleFinish();
                    onClose();
                  }}
                  class='cursor-pointer inline-flex min-w-40 items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-transparent px-6 py-2 text-center font-medium text-accent-2 transition hover:border-accent-2 hover:bg-accent-2 hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50 md:text-lg'
                >
                  Unsuitable company
                </span>
                <span
                  onClick={() => {
                    updateDialOutcomes(7, 'Current customer');
                    setHappenedIndex(7);
                    handleFinish();
                    onClose();
                  }}
                  class='cursor-pointer inline-flex min-w-40 items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-transparent px-6 py-2 text-center font-medium text-accent-2 transition hover:border-accent-2 hover:bg-accent-2 hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50 md:text-lg'
                >
                  Current customer
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BasicModal>
  );
};

export default DiscardModal;
