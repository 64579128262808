import React, { useState } from 'react';
import BasicModal from '../../Common/BasicModel';
import Draggable from 'react-draggable';

const CallNotesModal = ({
  isOpen,
  onClose,
  formObjNotes,
  setFormObjNotes,
  setFormCallNotes,
}) => {
  const handleTimeStamps = () => {
    console.log('Time Stamps called');
    const currentTime = new Date();
    const NotesformattedTime = currentTime.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      timeZoneName: 'short',
    });
    const NotesformattedDate = currentTime.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });
    const valueAppend = NotesformattedDate + ' ' + NotesformattedTime + '\n';
    console.log(valueAppend);
    if (
      formObjNotes.callNotes === undefined ||
      formObjNotes.callNotes === null
    ) {
      setFormObjNotes({ ...formObjNotes, callNotes: valueAppend + '\n' });
    } else {
      setFormObjNotes({
        ...formObjNotes,
        callNotes: formObjNotes.callNotes + valueAppend + '\n',
      });
    }
  };

  const handleSubmitNotes = (e) => {
    e.preventDefault();
    console.log(formObjNotes);
    if (
      formObjNotes.callNotes === undefined ||
      formObjNotes.callNotes === null
    ) {
      setFormObjNotes({ ...formObjNotes, callNotes: ' ' });
    }
    setFormCallNotes(formObjNotes.callNotes);
    fetch('/call-options-notes', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formObjNotes),
    }).then((r) => {
      if (r.ok) {
      } else if (r.status === 401) {
        window.location.href = '/login';
      } else {
      }
    });
  };

  const handleChangeNotes = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setFormObjNotes({ ...formObjNotes, [name]: value });
  };

  return (
    <BasicModal open={isOpen} handleClose={onClose}>
      <div
        id=''
        class='absolute left-[75%] bottom-[20%] -translate-x-[50%] cursor-move'
      >
        {' '}
        <Draggable cancel={'textarea'}>
          <div class='m-3 flex justify-center mt-0 transition-all ease-out hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 sm:mx-auto sm:w-full sm:max-w-xl'>
            <div class='md:w-[576px] sm:w-[300px] pointer-events-auto flex flex-col rounded-xl border bg-white shadow-sm'>
              <div class='flex items-center justify-between border-b-[2px] border-b-secondary/20 px-4 py-3'>
                <h5 class='text-xl font-semibold'>Call Notes</h5>
                <button
                  onClick={onClose}
                  type='button'
                  class='inline-flex h-8 w-8 items-center justify-center rounded-full border border-transparent text-sm font-semibold text-gray-800 transition hover:bg-gray-100 disabled:pointer-events-none disabled:opacity-50 dark:text-white dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600'
                  data-hs-overlay='#call-notes-modal'
                >
                  <span class='sr-only'>Close</span>
                  <svg
                    class='h-4 w-4 flex-shrink-0'
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  >
                    <path d='M18 6 6 18' />
                    <path d='m6 6 12 12' />
                  </svg>
                </button>
              </div>

              <div class='overflow-y-auto p-4'>
                <input
                  type={'hidden'}
                  name={'leadId'}
                  value={formObjNotes.leadId}
                />
                <input
                  type={'hidden'}
                  name={'tenantId'}
                  value={formObjNotes.tenantId}
                />
                <textarea
                  name='callNotes'
                  id='caller-notes-area'
                  rows='5'
                  class='block resize-none w-full overflow-y-auto rounded-lg border-2 border-secondary/20 px-4 py-3 text-lg font-medium transition focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500/20 [&::-webkit-scrollbar-thumb]:bg-secondary/80 [&::-webkit-scrollbar-track]:bg-secondary/20 [&::-webkit-scrollbar]:w-2'
                  placeholder='Start typing here...'
                  autoFocus={true}
                  onChange={handleChangeNotes}
                  value={formObjNotes.callNotes}
                >
                  {formObjNotes.callNotes}
                </textarea>
              </div>

              <div class='flex items-center justify-between p-4'>
                <button
                  onClick={handleTimeStamps}
                  type='button'
                  class='inline-flex items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-transparent px-6 py-2 text-lg font-medium text-accent-2 transition hover:border-accent-2 hover:bg-accent-2 hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50'
                >
                  Time Stamp
                </button>

                <button
                  onClick={(e) => {
                    onClose(e);
                    handleSubmitNotes(e);
                  }}
                  data-hs-overlay='#call-notes-modal'
                  type='button'
                  class='inline-flex items-center justify-center gap-x-2 rounded-full border border-accent-2 bg-accent-2 px-6 py-2 text-center font-medium text-white transition hover:border-primary hover:bg-primary hover:text-white focus:outline-none focus:ring focus:ring-primary/20 disabled:pointer-events-none disabled:opacity-50 md:px-10 md:text-lg'
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </Draggable>
      </div>
    </BasicModal>
  );
};

export default CallNotesModal;
